import React, { useState } from 'react';
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBCollapse,
  MDBBtn
} from "mdb-react-ui-kit";
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

export default function Navbar() {
  const [openBasic, setOpenBasic] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const logout = () => {
    Cookies.remove('email');
    Cookies.remove('Id');
    Cookies.remove('token');
    Cookies.remove('Picture');
    window.location.href = "/";
  }

  return (
    <MDBNavbar expand='lg' style={{ position: "sticky", top: "0", backgroundColor: "black", zIndex: "9999" }}>
      <MDBContainer fluid>
        <MDBNavbarBrand href='#'>
          <Link to="/">
            <div style={{ textAlign: 'center', alignContent: "center", alignItems: "center" }}>
              <img src="../Assets/logo.png" alt="" style={{ width: "120px" }} />
            </div>
          </Link>
        </MDBNavbarBrand>

        <MDBNavbarToggler
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setOpenBasic(!openBasic)}
        >
          <MDBIcon icon='bars' fas style={{ color: "white" }} />
        </MDBNavbarToggler>

        <MDBCollapse navbar open={openBasic} style={{ color: "white" }}>
          <MDBNavbarNav className='justify-content-start align-items-start' style={{ marginTop: "20px" }}>
            <MDBNavbarItem style={{ marginRight: "10px", marginTop: "5px" }}>
              <MDBNavbarLink active aria-current='page' href='#' style={{ marginRight: "10px", marginTop: "5px" }}>
                <Link to="/services" className="links">Services</Link>
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem style={{ marginRight: "10px", marginTop: "5px" }}>
              <MDBNavbarLink active aria-current='page' href=''>
                <Link to="/packages" className="links">Packages</Link>
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem style={{ marginRight: "10px", marginTop: "5px" }}>
              <MDBNavbarLink active aria-current='page' href='#'>
                <Link to="/about-viewers" className="links">About Viewers</Link>
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem style={{ marginRight: "20px", marginTop: "5px" }}>
              <MDBNavbarLink active aria-current='page' href='#'>
                <Link to="/contact" className="links">Contact Us</Link>
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem style={{ marginRight: "20px", marginTop: "5px" }}>
              <MDBNavbarLink active aria-current="page" href="#">
                <Link to="/about-us" className="links">
                  About Us
                </Link>
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem style={{ marginRight: "20px", marginTop: "7px" }}>
              <MDBDropdown show={isOpen} onClick={toggleDropdown} style={{ color: "white", marginTop: "-4px" }}>
                <MDBDropdownToggle
                  tag="a"
                  className="nav-link d-flex align-items-center"
                  href="#"
                  style={{ color: "white" }}
                >
                  <img
                    src={Cookies.get('Picture').startsWith('https://') ? Cookies.get('Picture') : `${process.env.REACT_APP_BASE_URL}/files/${Cookies.get('Picture')}`}
                    className="rounded-circle"
                    height="30"
                    width="30"
                    alt="Avatar"
                    loading="lazy"
                  />
                </MDBDropdownToggle>
                <MDBDropdownMenu style={{ padding: "10px" }}>
                  <MDBDropdownItem>
                    <a href={`http://localhost:3001/?seekers_Id=${btoa(Cookies.get('Id'))}&seekers_d=${btoa(Cookies.get('Picture'))}&seekers_e=${btoa(Cookies.get('email'))}`}>Seekers Portal</a>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <Link to="/viewer-details">Become a Viewer</Link>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <Link onClick={logout} to="/">Logout</Link>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
}
