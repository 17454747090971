import React, { useEffect } from "react";
import Navbar from "../Components/navbar";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Lognavbar from "../Components/lognavbar";
import Cookies from "js-cookie";
import Footer from "./footer";
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardText,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

export default function About() {
  useEffect(() => {
    document.body.style.fontFamily = "bahnschrift";
    document.body.style.backgroundColor = "#000000";
  }, []);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <div className="about">
      {Cookies.get("Id") ? <Navbar /> : <Lognavbar />}
      <div className="head">
        <h1
          className="mainheading"
          style={{ color: "#ff4e00", marginTop: "2%" }}
        >
          About Viewers
        </h1>
        <center>
          <MDBRow
            id="commission"
            className="cardss"
            style={{ marginTop: "1%" }}
          >
            <MDBCol md={6}>
              <MDBCard
                style={{ backgroundColor: "#1a2229", borderRadius: "30px" }}
              >
                <MDBCardImage
                  src="../Assets/images/13.png"
                  position="top"
                  alt="..."
                  style={{
                    height: "250px",
                    borderTopLeftRadius: "30px",
                    borderTopRightRadius: "30px",
                  }}
                />
                <MDBCardBody>
                  <h4 style={{ color: "#ff4e00" }}>Commission Free</h4>
                  <MDBCardText>
                    <p style={{ color: "white",textAlign:"justify" }}>
                      Asare Viewing operates on a commission-free model, where
                      our Viewers are paid per viewing rather than on
                      commission. This eliminates any incentive for them to
                      misrepresent property details. Our Viewers are dedicated
                      to providing honest, unbiased assessments, allowing you to
                      make informed decisions about renting or buying. By
                      removing commission-driven motivations, we ensure a
                      transparent and trustworthy property viewing experience
                      focused solely on your needs.
                    </p>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol md={6}></MDBCol>
          </MDBRow>
          <MDBRow id="Thorough" className="cardss">
            <MDBCol md={6}></MDBCol>
            <MDBCol md={6}>
              <MDBCard
                style={{ backgroundColor: "#1a2229", borderRadius: "30px" }}
              >
                <MDBCardImage
                  src="../Assets/images/1.png"
                  position="top"
                  alt="..."
                  style={{
                    height: "250px",
                    borderTopLeftRadius: "30px",
                    borderTopRightRadius: "30px",
                  }}
                />
                <MDBCardBody>
                  <h4 style={{ color: "#ff4e00" }}>
                    Thorough Onboarding Process
                  </h4>
                  <MDBCardText>
                    <p style={{ color: "white",textAlign:"justify" }}>
                      Asare Viewing has a rigorous selection process for
                      Viewers. While open to all applicants, we carefully choose
                      candidates who can provide exceptional property viewing
                      experiences. The onboarding includes a background check,
                      and a unique assessment aligned with our values. Only
                      those who successfully complete this rigorous process are
                      granted the opportunity to start viewing properties and
                      advance within our Viewer ranks. This stringent procedure
                      ensures our Viewers possess the expertise, integrity, and
                      dedication required to deliver exceptional service to our
                      clients.
                    </p>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
          <MDBRow id="Code" className="cardss">
            <MDBCol md={6}>
              <MDBCard
                style={{ backgroundColor: "#1a2229", borderRadius: "30px" }}
              >
                <MDBCardImage
                  src="../Assets/images/5.png"
                  position="top"
                  alt="..."
                  style={{
                    height: "250px",
                    borderTopLeftRadius: "30px",
                    borderTopRightRadius: "30px",
                  }}
                />
                <MDBCardBody>
                  <h4 style={{ color: "#ff4e00" }}>Code of Conduct</h4>
                  <MDBCardText>
                    <p style={{ color: "white",textAlign:"justify" }}>
                      Asare Viewing Viewers adhere to a strict Code of Conduct
                      centered on professionalism, confidentiality,
                      impartiality, and ethical decision-making. This ensures
                      our clients receive a consistently trustworthy and
                      exceptional property viewing experience.
                    </p>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol md={6}></MDBCol>
          </MDBRow>
          <MDBRow id="Collaboration" className="cardss">
            <MDBCol md={6}></MDBCol>
            <MDBCol md={6}>
              <MDBCard
                style={{ backgroundColor: "#1a2229", borderRadius: "30px" }}
              >
                <MDBCardImage
                  src="../Assets/images/9.png"
                  position="top"
                  alt="..."
                  style={{
                    height: "250px",
                    borderTopLeftRadius: "30px",
                    borderTopRightRadius: "30px",
                  }}
                />
                <MDBCardBody>
                  <h4 style={{ color: "#ff4e00" }}>
                    Collaborative Relationships with Estate Agents
                  </h4>
                  <MDBCardText>
                    <p style={{ color: "white",textAlign:"justify" }}>
                      While we may not always align with certain agent
                      practices, Asare Viewing Viewers work to maintain
                      professional relationships with estate agents. Their
                      expertise in organizing viewings and gleaning insights
                      from agents helps streamline the process, potentially
                      yielding improved outcomes for our clients.
                    </p>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
          <MDBRow id="Personalized" className="cardss">
            <MDBCol md={6}>
              <MDBCard
                style={{ backgroundColor: "#1a2229", borderRadius: "30px" }}
              >
                <MDBCardImage
                  src="../Assets/images/11.png"
                  position="top"
                  alt="..."
                  style={{
                    height: "250px",
                    borderTopLeftRadius: "30px",
                    borderTopRightRadius: "30px",
                  }}
                />
                <MDBCardBody>
                  <h4 style={{ color: "#ff4e00" }}>
                    Personalized Viewing Experiences
                  </h4>
                  <MDBCardText>
                    <p style={{ color: "white",textAlign:"justify" }}>
                      At Asare Viewing, our Viewers meticulously tailor the
                      property viewing process to each client's specific
                      requirements and preferences, ensuring a highly
                      personalized experience that aligns with their unique
                      needs and preferences.
                    </p>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol md={6}></MDBCol>
          </MDBRow>
        </center>
      </div>
      <Footer />
    </div>
  );
}
