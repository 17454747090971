import React, { useEffect } from 'react';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import Lognavbar from '../Components/lognavbar';
import Footer from './footer';
import Navbar from '../Components/navbar';

export default function EmailSent() {

  useEffect(()=>{
    document.body.style.fontFamily="bahnschrift";
    document.body.style.backgroundColor="#000000";
  },[])

  return (
    <div className='about'>
        <Lognavbar />
        <div className='head'>
          <center>
            <img src="../Assets/send.gif" alt="" className='emailicon'/>
            <h4 style={{color:"#ff4e00"}} className='emailhead'>Verification Email sent</h4>
          </center>
        </div>
        <Footer/>
    </div>
  )
}
