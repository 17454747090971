import React, { useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBInput,
  MDBSpinner

  ,  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
}
from 'mdb-react-ui-kit';
import {MDBCheckbox} from 'mdb-react-ui-kit';
import Select from 'react-select';
import Lognavbar from '../Components/lognavbar';
import {Link} from 'react-router-dom';
import Footer from './footer';
import Cookies from 'js-cookie';
import Navbar from '../Components/navbar';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

export default function Register() {
  useEffect(()=>{
    document.body.style.fontFamily="bahnschrift";
    document.body.style.backgroundColor="#000000";
  },[])

  const initialForm={
    firstname: '',
    lastname: '',
    profilePicture:null,
    gender:'',
    dateofBirth:null,
    email:'',
    mobile:'',
    accomodation:'',
    password:'',
    confirm:'',
  }

  const [formData, setFormData] = useState(initialForm);
  const [submit,setSubmit]=useState(false);
  const [error,setError]=useState('');

  const option = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'Other', label: 'Other' },
  ];

  const option1 = [
    { value: 'student', label: 'Student Accomodation' },
    { value: 'nonstudent', label: 'Non-Student Accomodation' },
  ];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      color: 'white',
      backgroundColor: '#000000', 
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? '#007BFF' : '#FFFFFF',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: 'white',
    }),
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'profilePicture') {
      const file = files[0]; 

      setFormData((prevFormData) => ({
        ...prevFormData,
        profilePicture: file, 
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleGenderChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : ''; 

    setFormData((prevFormData) => ({
      ...prevFormData,
      gender: selectedValue, 
    }));
  };

  const handleAccomodationChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : ''; 

    setFormData((prevFormData) => ({
      ...prevFormData,
      accomodation: selectedValue, 
    }));
  };

  const handleGoogleAuth = () => {
    window.location.href = 'https://api.asareviewing.com/auth/google';
  };

  const handleFacebookAuth = () => {
    window.location.href = 'https://api.asareviewing.com/auth/facebook';
  };

  async function handleRegister(e){
    e.preventDefault();

    if(formData.password==formData.confirm){

      console.log(formData);
      try {
        setSubmit(true);
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/asare/register`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        const responseData = response.data;

        if(responseData.message=='success'){
          window.location.href="/sent";
        }
        else if(responseData.message=="Error_Email"){
          errorNotify();
        }
        window.location.assign('https://seeker.asareviewing.com/');
        setFormData(initialForm);
        setSubmit(false);
        
      } catch (error) {
        console.error('Error:', error);
      }
    }
    else{
      errorNotifyNew();
    }
  }

  const errorNotify = () => {
    toast.error('Email already exist', {
      position: toast.POSITION.TOP_CENTER 
    });
  };

  const errorNotifyNew = () => {
    toast.error('Password & Confirm Password must be same', {
      position: toast.POSITION.TOP_CENTER 
    });
  };



  const [centredModal1, setCentredModal1] = useState(false);

  const toggleOpen1 = () => setCentredModal1(!centredModal1);
  return (
    <div>
    <Lognavbar/>
        <MDBContainer fluid className='d-flex align-items-center justify-content-center' style={{ minHeight: '100vh' }}>
        <ToastContainer/>
            <div className='m-5' style={{width:"400px"}}>
                <h3 style={{color:"white",marginTop:"5%"}}>REGISTER</h3>
                <MDBBtn className='mb-4 w-100 gradient-custom-4' size='lg' style={{borderRadius:"0",color:"white",backgroundColor:"black",border:"1px solid white",marginTop:"20px"}} onClick={handleGoogleAuth}><img src='../Assets/google.png' style={{width:"20px",marginRight:"20px",marginTop:"-4px"}}></img>Sign Up with Google</MDBBtn>
                <MDBBtn className='mb-4 w-100 gradient-custom-4' size='lg' style={{borderRadius:"0",color:"white",backgroundColor:"black",border:"1px solid white",marginTop:"-10px"}} onClick={handleFacebookAuth}><img src='../Assets/facebook.png' style={{width:"25px",marginRight:"15px",marginTop:"-4px"}}></img>Sign Up with Facebook</MDBBtn>

                <hr style={{color:"white"}}/>
              <form onSubmit={handleRegister}>
                <div className="mb-4" style={{marginTop:"10px"}}>
                    <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>First Name</label>
                    <MDBInput size='lg' type='text' style={{borderRadius:"0"}} 
                      name='firstname'
                      required
                      value={formData.firstname}
                      onChange={handleInputChange}
                    />
                </div>
                <div className="mb-4" style={{marginTop:"10px"}}>
                    <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Last Name</label>
                    <MDBInput size='lg' type='text' style={{borderRadius:"0"}}
                      name='lastname'
                      value={formData.lastname}
                      onChange={handleInputChange}
                    />
                </div>
                <div className="mb-4" style={{marginTop:"10px"}}>
                    <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Profile Picture</label>
                    <MDBInput size='lg' type='file' style={{borderRadius:"0"}}
                      name='profilePicture'
                      onChange={handleInputChange}
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Gender</label>
                    <Select
                        id="multi-select"
                        name='gender'
                        required
                        options={option}
                        isMulti={false}
                        onChange={handleGenderChange}
                        styles={customStyles}
                        placeholder="Select Answer"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Date of Birth</label>
                    <MDBInput size='lg' type='date'style={{color:"white"}}
                      name='dateofBirth'
                      required
                      value={formData.dateofBirth}
                      onChange={handleInputChange}
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Email</label>
                    <MDBInput size='lg' type='email'
                      name='email'
                      required
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Mobile</label>
                    <MDBInput size='lg' type='text'
                      name='mobile'
                      required
                      value={formData.mobile}
                      onChange={handleInputChange}
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>What accomodation are you looking for?</label>
                    <Select
                        id="multi-select"
                        name='accomodation'
                        required
                        options={option1}
                        isMulti={false}
                        onChange={handleAccomodationChange}
                        styles={customStyles}
                        placeholder="Select Answer"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Password</label>
                    <MDBInput size='lg' type='password' 
                      name='password'
                      required
                      value={formData.password}
                      onChange={handleInputChange}
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Re-Enter Password</label>
                    <MDBInput size='lg' type='password'
                      name='confirm'
                      required
                      value={formData.confirm}
                      onChange={handleInputChange}
                    />
                </div>

                <div style={{display:"flex"}}>
                    <MDBCheckbox
                        id='controlledCheckbox'
                        required
                    />
                    <p style={{color:"white",textAlign:"left",marginLeft:"3px"}}>By Clicking checkbox, you confirm that you have read and accept <span onClick={toggleOpen1} style={{color:"blue",cursor:"pointer"}}>
                      
                    Terms & Services
                      
                      
                      </span>.</p>
                </div>

                <MDBBtn className='mb-4 w-100 gradient-custom-4' size='lg' style={{borderRadius:"0",color:"black",backgroundColor:"white"}}>
                  {submit?(
                    <MDBSpinner></MDBSpinner>
                  ):(
                    <span>Register</span>
                  )}
                </MDBBtn>
                <p style={{color:"white"}}>Already have an Account? <a href="https://seeker.asareviewing.com/" style={{color:"#ff4e00"}}>Login</a></p>
              </form>
            </div>
        </MDBContainer>




        <MDBModal tabIndex='-1' open={centredModal1} onClose={() => setCentredModal1(false)}>
  <MDBModalDialog  size="lg" centered>
    <MDBModalContent className="privacy">
      <MDBModalHeader>
        <MDBModalTitle className="text-center w-100">
          <h3 style={{ color: "#ff4e00" }}>
            TERMS AND CONDITIONS OF USE
          </h3>
        </MDBModalTitle>
        <MDBBtn className='btn-close' color='none' onClick={toggleOpen1}></MDBBtn>
      </MDBModalHeader>
      <MDBModalBody>
      <div style={{ textAlign: "left", color: 'white' }}>
          <p>
            These terms and conditions ("Terms") apply between you, the User of this Website (including any sub-domains, unless expressly excluded by their own terms and conditions), and Asare Viewing, the owner and operator of this Website. Please read these Terms carefully as they affect your legal rights. By accessing and using this Website, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, you should stop using the Website immediately.
          </p>

          <h3 style={{ color: '#ff4e00' }}>1. Intellectual Property and Acceptable Use</h3>
          <p>
            1.1 All Content included on the Website, unless uploaded by Users, is the property of Asare Viewing, our affiliates, or other relevant third parties. "Content" means any text, graphics, images, audio, video, software, data compilations, page layout, underlying code and software, and any other form of information capable of being stored in a computer that appears on or forms part of this Website, including any such Content uploaded by Users. By continuing to use the Website, you acknowledge that such Content is protected by copyright, trademarks, database rights, and other intellectual property rights.
          </p>
          <p>
            1.2 You may retrieve, display, and view the Content on a computer screen or mobile device, store the Content in electronic form on a disk (but not on any server or other storage device connected to a network), and print one copy of the Content for your own personal, non-commercial use, provided you keep intact all and any copyright and proprietary notices.
          </p>
          <p>
            1.3 You must not otherwise reproduce, modify, copy, distribute, or use for commercial purposes any Content without the written permission of Asare Viewing.
          </p>
          <p>
            1.4 You acknowledge that you are responsible for any Content you may submit via the Website, including the legality, reliability, appropriateness, originality, and copyright of any such Content. You may not upload to, distribute, or otherwise publish through the Website any Content that (i) is confidential, proprietary, false, fraudulent, libelous, defamatory, obscene, threatening, invasive of privacy or publicity rights, infringing on intellectual property rights, abusive, illegal, or otherwise objectionable; (ii) may constitute or encourage a criminal offense, violate the rights of any party, or otherwise give rise to liability or violate any law; or (iii) may contain software viruses, political campaigning, chain letters, mass mailings, or any form of "spam." You may not use a false email address or other identifying information, impersonate any person or entity, or otherwise mislead as to the origin of any Content.
          </p>
          <p>
            1.5 You represent and warrant that you own or otherwise control all the rights to the Content you post; that the Content is accurate; that use of the Content you supply does not violate any provision of these Terms and will not cause injury to any person; and that you will indemnify Asare Viewing for all claims resulting from Content you supply.
          </p>

          <h3 style={{ color: '#ff4e00' }}>2. Prohibited Use</h3>
          <p>
            2.1 You may not use the Website for any purpose that is unlawful or prohibited by these Terms.
          </p>
          <p>
            2.2 You may not use the Website in any manner that could damage, disable, overburden, or impair the Website or interfere with any other party's use and enjoyment of the Website.
          </p>
          <p>
            2.3 You may not make, transmit, or store electronic copies of Content protected by copyright without the permission of the owner.
          </p>

          <h3 style={{ color: '#ff4e00' }}>3. Registration</h3>
          <p>
            3.1 You must ensure that the details provided by you on registration or at any time are correct and complete.
          </p>
          <p>
            3.2 You must inform us immediately of any changes to the information that you provide when registering by updating your personal details to ensure we can communicate with you effectively.
          </p>
          <p>
            3.3 We may suspend or cancel your registration with immediate effect for any reasonable purposes or if you breach these Terms.
          </p>
          <p>
            3.4 You may cancel your registration at any time by informing us in writing to the address at the end of these Terms. If you do so, you must immediately stop using the Website. Cancellation or suspension of your registration does not affect any statutory rights.
          </p>

          <h3 style={{ color: '#ff4e00' }}>4. Passwords and Security</h3>
          <p>
            4.1 When you register on this Website, you will be asked to create a password, which you should keep confidential and not disclose or share with anyone.
          </p>
          <p>
            4.2 If we have reason to believe that there is or is likely to be any misuse of the Website or breach of security, we may require you to change your password or suspend your account.
          </p>

          <h3 style={{ color: '#ff4e00' }}>5. Privacy Policy</h3>
          <p>
            5.1 Use of the Website is also governed by our Privacy Policy, which is incorporated into these Terms by this reference. To view the Privacy Policy, please click on the following link: <a href="asareviewing.com/privacy-policy" style={{ color: '#ff4e00' }}>Privacy Policy</a>.
          </p>

          <h3 style={{ color: '#ff4e00' }}>6. Availability of the Website and Disclaimers</h3>
          <p>
            6.1 Any online facilities, tools, services, or information that Asare Viewing makes available through the Website (the "Service") is provided "as is" and on an "as available" basis. We give no warranty that the Service will be free of defects and/or faults. To the maximum extent permitted by law, we provide no warranties (express or implied) of fitness for a particular purpose, accuracy of information, compatibility, and satisfactory quality. Asare Viewing is under no obligation to update information on the Website.
          </p>
          <p>
            6.2 Whilst Asare Viewing uses reasonable endeavors to ensure that the Website is secure and free of errors, viruses, and other malware, we give no warranty or guarantee in that regard and all Users take responsibility for their own security, that of their personal details, and their computers.
          </p>
          <p>
            6.3 Asare Viewing accepts no liability for any disruption or non-availability of the Website.
          </p>
          <p>
            6.4 Asare Viewing reserves the right to alter, suspend, or discontinue any part (or the whole) of the Website, including but not limited to any products and/or services available. These Terms shall continue to apply to any modified version of the Website unless it is expressly stated otherwise.
          </p>

          <h3 style={{ color: '#ff4e00' }}>7. Limitations of Liabilities</h3>
          <p>
            7.1 Nothing in these Terms will limit or exclude our or your liability for death or personal injury resulting from negligence, fraud, fraudulent misrepresentation, or any other liability that cannot be excluded or limited by applicable law.
          </p>
          <p>
            7.2 We will not be liable to you in respect of any losses arising out of events beyond our reasonable control.
          </p>
          <p>
            7.3 To the maximum extent permitted by law, Asare Viewing accepts no liability for any business losses, such as loss of profits, income, revenue, anticipated savings, business, contracts, goodwill, or commercial opportunities; loss or corruption of any data, database, or software; or any special, indirect, or consequential loss or damage.
          </p>

          <h3 style={{ color: '#ff4e00' }}>8. General</h3>
          <p>
            8.1 You may not transfer any of your rights under these Terms to any other person. We may transfer our rights under these Terms where we reasonably believe your rights will not be affected.
          </p>
          <p>
            8.2 We may revise these Terms at any time by updating this page. You should check this page regularly to review the current Terms, as they are binding on you. Certain provisions of these Terms may be superseded by expressly designated legal notices or terms located on particular pages at this Website.
          </p>
          <p>
            8.3 These Terms, together with our Privacy Policy, constitute the entire agreement between you and Asare Viewing in relation to your use of this Website and supersede all prior agreements and understandings.
          </p>
          <p>
            8.4 The Contracts (Rights of Third Parties) Act 1999 shall not apply to these Terms, and no third party will have any right to enforce or rely on any provision of these Terms.
          </p>
          <p>
            8.5 If any court or competent authority finds that any provision of these Terms (or part of any provision) is invalid, illegal, or unenforceable, that provision or part-provision will, to the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of these Terms will not be affected.
          </p>
          <p>
            8.6 Unless otherwise agreed, no delay, act, or omission by a party in exercising any right or remedy will be deemed a waiver of that, or any other, right or remedy.
          </p>
          <p>
            8.7 This Agreement shall be governed by and interpreted according to the law of England and Wales, and all disputes arising under the Agreement (including non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of the English and Welsh courts.
          </p>

          <h3 style={{ color: '#ff4e00' }}>9. Contact Details</h3>
          <p>
            Asare Viewing is a company incorporated in England and Wales with registered number Asare Viewing Ltd whose registered address is One Business Village, Emily Street, East Riding of Yorkshire, HUS IND and it operates the Website asareviewing.com. You can contact Asare Viewing by email at <a href="mailto:contactus@asareviewing.com" style={{ color: '#ff4e00' }}>contactus@asareviewing.com</a>.
          </p>
        </div>
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn className="buttonclose" color='secondary' onClick={toggleOpen1}>
          Close
        </MDBBtn>
      </MDBModalFooter>
    </MDBModalContent>
  </MDBModalDialog>
</MDBModal>
        <Footer/>
    </div>
  )
}
