import React, { useEffect, useState } from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  MDBRow,
  MDBCol,
  MDBCarousel,
  MDBCarouselItem,
  MDBCarouselCaption,
  MDBSpinner,
} from "mdb-react-ui-kit";
import {
  MDBCardImage,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBInput,
  MDBTextArea,
} from "mdb-react-ui-kit";
import Cookies from "js-cookie";
import Navbar from "../Components/navbar";
import Lognavbar from "../Components/lognavbar";
import ReactStars from "react-rating-stars-component";
import Carousel from "better-react-carousel";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./footer";

export default function Home() {
  useEffect(() => {
    GetReviews();
    document.body.style.fontFamily = "bahnschrift";
    document.body.style.backgroundColor = "#000000";
    const queryParams = new URLSearchParams(window.location.search);
    const param = queryParams.get("type");
    if (param) {
      const client_e = queryParams.get("client_e");
      const client_d = queryParams.get("client_d");
      const client_Id = queryParams.get("client_Id");
      if (client_e) {
        Cookies.set("email", atob(client_e), { expires: 1 });
      }
      if (client_d) {
        Cookies.set("Id", atob(client_Id), { expires: 1 });
      }
      if (client_Id) {
        Cookies.set("Picture", atob(client_d), { expires: 1 });
      }
    }
  }, []);

  const initialForm = {
    name: "",
    rating: 0,
    feedback: "",
  };

  const [formData, setFormData] = useState(initialForm);
  const [submit, setSubmit] = useState(false);
  const [reviews, setReviews] = useState([]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "profilePicture") {
      const file = files[0];

      setFormData((prevFormData) => ({
        ...prevFormData,
        profilePicture: file,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  async function handleReview(e) {
    e.preventDefault();

    try {
      setSubmit(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/asare/review/create`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = response.data;
      if (responseData.message == "success") {
        successNotify();
      }
      setFormData(initialForm);
      GetReviews();
      setSubmit(false);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function GetReviews() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/asare/review/get`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = response.data.data;
      setReviews(responseData);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const handleRating = (e) => {
    formData.rating = e;
  };

  const successNotify = () => {
    toast.success("Review Submitted", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const [slideshow1, setslideshow1] = useState();
  const [slideshow2, setslideshow2] = useState();
  const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
  const averageRating = (totalRating / reviews.length).toFixed(2);

  async function getslideshow1() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/getslideshow1`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("SlideShow1", response.data.data);
      setslideshow1(response.data.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function getslideshow2() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/getslideshow2`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("SlideShow2", response.data.data);
      setslideshow2(response.data.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  useEffect(() => {
    getslideshow1();
    getslideshow2();
  }, []);

  return (
    <div>
      {Cookies.get("Id") ? <Navbar /> : <Lognavbar />}
      <ToastContainer />
      <MDBCarousel showIndicators showControls fade>
        <MDBCarouselItem itemId={1}>
          <img src="../Assets/images/16.png" alt="..." className="slider" />
          <MDBCarouselCaption>
            <h1 className="title">FOUND A PROPERTY?</h1>
            <p className="fw-bolder" style={{   color: 'white',
    textShadow: '-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black',
    border:1}}>
              GET A THOROUGH REPORT ABOUT IT BEFORE YOU
COMMIT
            </p>
          </MDBCarouselCaption>
        </MDBCarouselItem>

        <MDBCarouselItem itemId={2}>
          <img src="../Assets/images/15.png" alt="..." className="slider" />
          <MDBCarouselCaption>
            <h1 className="title">FOUND A PROPERTY?</h1>
            <p className="fw-bolder" style={{   color: 'white',
    textShadow: '-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black',
    border:1}}>
              GET A THOROUGH REPORT ABOUT IT BEFORE YOU
COMMIT
            </p>
          </MDBCarouselCaption>
        </MDBCarouselItem>

        <MDBCarouselItem itemId={3}>
          <img src="../Assets/images/17.png" alt="..." className="slider" />
          <MDBCarouselCaption>
            <h1 className="title">FOUND A PROPERTY?</h1>
            <p className="fw-bolder" style={{   color: 'white',
    textShadow: '-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black',
    border:1}}>
            GET   A THOROUGH REPORT ABOUT IT BEFORE YOU
COMMIT
            </p>
          </MDBCarouselCaption>
        </MDBCarouselItem>
      </MDBCarousel>

      <div>
        <h2 style={{ marginTop: "4%", color: "#ff4e00", fontSize: "35px" }}>
          Things we help you confirm
        </h2>
        <p  className="fw-bolder" style={{ color: "white" }}>
          Because you can not make it to view the property in person, we help
          you look out for the following before you commit.
        </p>

        <MDBRow style={{ margin: "30px" }}>
  <MDBCol md={6}>
    <MDBCarousel
      showIndicators
      showControls
      fade
      key={slideshow1 && slideshow1.length}
      style={{ marginTop: "10px" }}
    >
      {slideshow1 &&
        slideshow1.map((slideshow, index) => (
          <MDBCarouselItem key={index + 1} itemId={index + 1}>
            <img
              src={`${process.env.REACT_APP_BASE_URL}/files/${slideshow.filename}`}
              className="d-block w-100"
              alt={slideshow.description || "Slideshow Image"}
              style={{
                width: "100%",
                objectFit: "center"
              }}
            />
            <MDBCarouselCaption>
              <h5>{slideshow.description}</h5>
            </MDBCarouselCaption>
          </MDBCarouselItem>
        ))}
    </MDBCarousel>
  </MDBCol>

  <MDBCol md={6}>
    <MDBCarousel
      showIndicators
      showControls
      fade
      key={slideshow2 && slideshow2.length}
      style={{ marginTop: "10px" }}
    >
      {slideshow2 &&
        slideshow2.map((slideshow, index) => (
          <MDBCarouselItem key={index + 1} itemId={index + 1}>
            <img
              src={`${process.env.REACT_APP_BASE_URL}/files/${slideshow.filename}`}
              className="d-block w-100"
              alt={slideshow.description || "Slideshow Image"}
              style={{
                width: "100%",
                objectFit: "center"
              }}
            />
            <MDBCarouselCaption>
              <h5>{slideshow.description}</h5>
            </MDBCarouselCaption>
          </MDBCarouselItem>
        ))}
    </MDBCarousel>
  </MDBCol>
</MDBRow>


        <center>
          <h4 style={{ marginTop: "4%", color: "#ff4e00", fontSize: "35px" }}>
            How does it work?
          </h4>
          <MDBRow
            className="row-cols-1 row-cols-md-3 g-4"
            style={{ width: "80%", marginTop: "30px" }}
          >
            <MDBCol>
              <MDBCard
                className="h-100"
                style={{ backgroundColor: "#1a2229", borderRadius: "30px" }}
              >
                <MDBCardBody>
                  <MDBCardTitle style={{ color: "#ff4e00" }}>
                    Step 1
                  </MDBCardTitle>
                  <MDBCardText>
                    <h4 style={{ color: "white" ,textAlign:"justify"}}>
                      Find a property online or by address
                    </h4>
                    <p style={{ color: "white",textAlign:"justify" }}>
                    Search for your ideal property online,
                    or do you already know of any property by address?
                    </p>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol>
              <MDBCard
                className="h-100"
                style={{ backgroundColor: "#1a2229", borderRadius: "30px" }}
              >
                <MDBCardBody>
                  <MDBCardTitle style={{ color: "#ff4e00" }}>
                    Step 2
                  </MDBCardTitle>
                  <MDBCardText>
                    <h4 style={{ color: "white",textAlign:"justify" }}>Submit it for viewing</h4>
                    <p style={{ color: "white",textAlign:"justify" }}>
                      Once you find a property of interest, submit it for a
                      viewing request through our platform.
                    </p>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol>
              <MDBCard
                className="h-100"
                style={{ backgroundColor: "#1a2229", borderRadius: "30px" }}
              >
                <MDBCardBody>
                  <MDBCardTitle style={{ color: "#ff4e00" }}>
                    Step 3
                  </MDBCardTitle>
                  <MDBCardText>
                    <h4 style={{ color: "white",textAlign:"justify" }}>
                      Get all the information you need
                      before you commit
                    </h4>
                    <p style={{ color: "white",textAlign:"justify" }}>
                      One of our trusted locals will visit the property on your
                      behalf and provide you with comprehensive information to
                      help you make an informed decision.
                    </p>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>

          <div style={{ marginTop: "3%" }}>
            <h4 style={{ color: "white" }}>
             Subscribe to a
              viewing package and our local will view for you.
            </h4>
            <Link to="/register">
              <MDBBtn
                style={{
                  color: "white",
                  backgroundColor: "#ff4e00",
                  marginTop: "10px",
                }}
              >
                Sign Up
              </MDBBtn>
            </Link>
          </div>

          <h2 style={{ marginTop: "4%", color: "#ff4e00", fontSize: "35px" }}>
            Trained & Trusted Local
          </h2>
          <p style={{ color: "white" }}>
            Who else will you want to look into your property for you
          </p>
          <center>
            <Carousel cols={4} rows={1} gap={10} loop>
              <Carousel.Item>
                <MDBCard
                  className="h-100"
                  style={{ backgroundColor: "#1a2229" }}
                >
                  <MDBCardImage
                    src="../Assets/images/13.png"
                    position="top"
                    alt="..."
                    style={{ height: "250px" }}
                  />
                  <MDBCardBody>
                    <MDBCardText>
                      <h4 style={{ color: "#ff4e00" }}>Commission Free</h4>
                      <Link to="/about-viewers#commission" style={{ color: "white" }}>
          Read More
        </Link>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </Carousel.Item>

              <Carousel.Item>
                <MDBCard
                  className="h-100"
                  style={{ backgroundColor: "#1a2229" }}
                >
                  <MDBCardImage
                    src="../Assets/images/1.png"
                    position="top"
                    alt="..."
                    style={{ height: "250px" }}
                  />
                  <MDBCardBody>
                    <MDBCardText>
                      <h4 style={{ color: "#ff4e00" }}>
                      Thorough Onboarding Process
                      </h4>
                      <Link to="/about-viewers#Thorough" style={{ color: "white" }}>
                        Read More
                      </Link>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </Carousel.Item>

              <Carousel.Item>
                <MDBCard
                  className="h-100"
                  style={{ backgroundColor: "#1a2229" }}
                >
                  <MDBCardImage
                    src="../Assets/images/5.png"
                    position="top"
                    alt="..."
                    style={{ height: "250px" }}
                  />
                  <MDBCardBody>
                    <MDBCardText>
                      <h4 style={{ color: "#ff4e00" }}>Code of Conduct</h4>
                      <Link to="/about-viewers#Code" style={{ color: "white" }}>
                        Read More
                      </Link>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </Carousel.Item>

              <Carousel.Item>
                <MDBCard
                  className="h-100"
                  style={{ backgroundColor: "#1a2229" }}
                >
                  <MDBCardImage
                    src="../Assets/images/9.png"
                    position="top"
                    alt="..."
                    style={{ height: "250px" }}
                  />
                  <MDBCardBody>
                    <MDBCardText>
                      <h4 style={{ color: "#ff4e00" }}>
                      Collaborative Relationships with Estate Agents
                      </h4>
                      <Link to="/about-viewers#Collaboration" style={{ color: "white" }}>
                        Read More
                      </Link>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </Carousel.Item>

              <Carousel.Item>
                <MDBCard
                  className="h-100"
                  style={{ backgroundColor: "#1a2229" }}
                >
                  <MDBCardImage
                    src="../Assets/images/11.png"
                    position="top"
                    alt="..."
                    style={{ height: "250px" }}
                  />
                  <MDBCardBody>
                    <MDBCardText>
                      <h4 style={{ color: "#ff4e00" }}>
                      Personalized Viewing Experiences
                      </h4>
                      <Link to="/about-viewers#Personalized" style={{ color: "white" }}>
                        Read More
                      </Link>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </Carousel.Item>
            </Carousel>
          </center>
        </center>

        <h2 style={{ marginTop: "4%", color: "#ff4e00", fontSize: "35px" }}>
          Rating & Reviews
        </h2>
        <p style={{ color: "white" }}>Overall Rating {averageRating} ({reviews.length})</p>

        <center>
        <Carousel cols={4} rows={1} gap={10} loop>
      {reviews.map((item, index) => (
        <Carousel.Item key={index}>
          <MDBCol>
            <MDBCard className="h-100" style={{ backgroundColor: "#1a2229", borderRadius: "0" }}>
              <MDBCardBody className="d-flex flex-column">
                <div>
                  {Array.from({ length: item.rating }, (_, starIndex) => (
                    <svg
                      key={starIndex}
                      className="star-icon"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                    >
                      <path d="M10 1l2.93 5.65 6.54.95-4.75 4.6 1.12 6.51L10 15.4l-5.83 3.32 1.12-6.51-4.75-4.6 6.54-.95L10 1z" />
                    </svg>
                  ))}
                </div>
                <MDBCardText className="flex-grow-1 d-flex flex-column justify-content-between">
                  <p style={{ marginTop: "15px", textAlign: "justify", color: "white" }}>
                    {item.feedback}
                  </p>
                  <div style={{ display: "flex" }}>
                    <div style={{ textAlign: "left" }}>
                      <h6 style={{ color: "white" }}>{item.name}</h6>
                      <p style={{ marginTop: "-5px", color: "#ff4e00" }}>
                        {new Date(item.createdAt).toLocaleString()}
                      </p>
                    </div>
                  </div>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </Carousel.Item>
      ))}
    </Carousel>
  </center>

        <center>
          <MDBCard
            className="m-5"
            style={{
              maxWidth: "600px",
              backgroundColor: "#1a2229",
              borderRadius: "0",
            }}
          >
            <MDBCardBody className="px-5">
              <h2 className="text-center mb-5" style={{ color: "#ff4e00" }}>
                Leave a Review
              </h2>
              <label
                htmlFor="form1"
                style={{ textAlign: "left", display: "block", color: "white" }}
              >
                Your Name
              </label>
              <MDBInput
                wrapperClass="mb-4"
                size="lg"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
              <label
                htmlFor="form1"
                style={{ textAlign: "left", display: "block", color: "white" }}
              >
                Rating
              </label>
              <ReactStars
                count={5}
                size={24}
                name="rating"
                activeColor="#ffd700"
                value={formData.rating}
                onChange={handleRating}
              />
              <label
                htmlFor="form1"
                style={{ textAlign: "left", display: "block", color: "white" }}
              >
                Review
              </label>
              <MDBTextArea
                contrast
                id="textAreaExample"
                label="Write here..."
                rows={4}
                name="feedback"
                value={formData.feedback}
                onChange={handleInputChange}
              ></MDBTextArea>
              <MDBBtn
                className="mb-4 w-100 gradient-custom-4"
                size="lg"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  borderRadius: "0",
                  marginTop: "10px",
                }}
                onClick={handleReview}
              >
                {submit ? <MDBSpinner></MDBSpinner> : <span>Post</span>}
              </MDBBtn>
            </MDBCardBody>
          </MDBCard>

          <h4 style={{ color: "white" }}>Sign up to get started</h4>
          <Link to="/register">
            <MDBBtn
              style={{
                color: "white",
                backgroundColor: "#ff4e00",
                marginTop: "10px",
              }}
            >
              Sign Up
            </MDBBtn>
          </Link>
        </center>
      </div>
      <Footer />
    </div>
  );
}
