import React, { useEffect } from "react";
import Navbar from "../Components/navbar";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Lognavbar from "../Components/lognavbar";
import Cookies from "js-cookie";
import Footer from "./footer";
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import "../App.css";
export default function Aboutus() {
  useEffect(() => {
    document.body.style.fontFamily = "Bahnschrift, Arial, sans-serif";
    document.body.style.backgroundColor = "#000000";
  }, []);

  return (
    <>
      {" "}
      <div className="about" style={{ padding: "20px" }}>
        {Cookies.get("Id") ? <Navbar /> : <Lognavbar />}
        <div className="head" style={{ textAlign: "center", marginTop: "2%" }}>
          <h1
            className="mainheading"
            style={{
              color: "#ff4e00",
              marginBottom: "20px",
              animation: "fadeIn 2s",
            }}
          >
            About Us
          </h1>

          <h6
            style={{
              color: "white",
              margin: "20px",
              fontSize: "18px",
              lineHeight: "1.6",
              textAlign:"justify"
            }}
          >
            Welcome to Asare Viewing, a community dedicated to assisting you in
            your property search as you embark on your journey to a new country
            or new city. We understand the challenges and pressures that come
            with relocating to a completely new environment. Our mission is to
            alleviate the burden by providing a comprehensive and professional
            property search service while you focus on more pressing matters.
          </h6>
          <center>
            <MDBRow
              className="cardss"
              style={{ marginTop: "1%", maxWidth: "600px", margin: "auto" }}
            >
              <MDBCol md={12} className="mb-4">
                <MDBCard
                  style={{
                    backgroundColor: "#1a2229",
                    borderRadius: "30px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    transition: "transform 0.3s",
                  }}
                  className="hoverable"
                >
                  <MDBCardBody>
                    <h4 style={{ color: "#ff4e00" }}>Our Commitment</h4>
                    <MDBCardText>
                      <p style={{ color: "white", textAlign: "justify" }}>
                        At Asare Viewing, we are committed to ensuring that you
                        have access to safe, comfortable, and suitable housing
                        options that meet your preferences and expectations.
                      </p>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>

            <MDBRow
              className="cardss"
              style={{ marginTop: "1%", maxWidth: "600px", margin: "auto" }}
            >
              <MDBCol md={12} className="mb-4">
                <MDBCard
                  style={{
                    backgroundColor: "#1a2229",
                    borderRadius: "30px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    transition: "transform 0.3s",
                  }}
                  className="hoverable"
                >
                  <MDBCardBody>
                    <h4 style={{ color: "#ff4e00" }}>Our Services</h4>
                    <MDBCardText style={{textAlign:"justify"}}>
                      <p style={{ color: "white" }}>
                        Our platform offers - and will offer more - a range of
                        services designed to streamline the property search
                        experience for migrants and immigrants. Here's how we
                        can help:
                      </p>
                      <ul style={{ color: "white", paddingLeft: "20px" }}>
                        <li>
                          <span style={{ color: "#ff4e00" }}>
                            Property Verification:
                          </span>{" "}
                          We prioritize the importance of ensuring that
                          properties meet the required standards of living. Our
                          dedicated team conducts thorough inspections and
                          verifications to ensure that the properties listed on
                          any platform, before you sign any contract, are in
                          excellent condition and meet the necessary standards.
                        </li>
                        <li>
                          <span style={{ color: "#ff4e00" }}>
                            Professional Assistance::
                          </span>{" "}
                          We also have a dedicated team available to provide
                          guidance and support throughout the property search
                          process. Whether it's answering queries, providing
                          advice, or assisting with negotiations, we are here to
                          ensure a smooth and hassle-free experience.
                        </li>
                      </ul>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>

            <MDBRow
              className="cardss"
              style={{ marginTop: "1%", margin: "auto" }}
            >
              <MDBCol md={12} className="mb-4">
                <MDBCard
                  style={{
                    backgroundColor: "#1a2229",
                    borderRadius: "30px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    transition: "transform 0.3s",
                  }}
                  className="hoverable"
                >
                  <MDBCardBody>
                    <h4 style={{ color: "#ff4e00" }}>Why Choose Us?</h4>
                    <MDBCardText style={{textAlign:"justify"}}>
                      <ul style={{ color: "white", paddingLeft: "20px" }}>
                        <li>
                          <span style={{ color: "#ff4e00" }}>
                            Safety and Security:
                          </span>{" "}
                          We prioritize the safety and security of our users. We take measures to ensure that the properties they find listed on any platform are legitimate and adhere to legal and safety standards.
                        </li>
                        <li>
                          <span style={{ color: "#ff4e00" }}>
                            Dedicated Support:
                          </span>{" "}
                          We understand that migrating to a new country can be
                          overwhelming. Our team provides personalized support
                          and assistance to help individuals navigate the
                          property search process with ease.
                        </li>
                      </ul>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>

            <h6
              style={{
                color: "white",
                margin: "20px",
                fontSize: "18px",
                lineHeight: "1.6",
                textAlign:"justify"
              }}
            >
              To see more of what we do and how we do it engage with us and
              follow us on our journey, kindly follow us on our social media
              channel <span style={{ color: "#ff4e00" }}>@AsareViewing</span> on
              Facebook, Instagram, LinkedIn, Twitter and YouTube
            </h6>
          </center>
        </div>
      </div>
      <Footer />
    </>
  );
}

// CSS
const style = document.createElement("style");
style.innerHTML = `
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  .hoverable:hover {
    transform: scale(1.05);
  }
`;

document.head.appendChild(style);
