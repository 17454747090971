import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBBtn,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

export default function Lognavbar() {
  const [openBasic, setOpenBasic] = useState(false);
  const [reg, setreg] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const token = Cookies.get("A_token");
    if (!token) {
      setreg(false);
    } else {
      setreg(true);
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlelogout = () => {
    Cookies.remove("A_token");
    Cookies.remove("A_Id");
    Cookies.remove("A_email");

    localStorage.removeItem("ID");
    window.location.href = "/login";
  };

  return (
    <MDBNavbar
      expand="lg"
      style={{
        position: "sticky",
        top: "0",
        backgroundColor: "black",
        zIndex: "9999",
      }}
    >
      <MDBContainer fluid>
        <MDBNavbarBrand href="#">
          <Link to="/">
            <div
              style={{
                textAlign: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <img src="../Assets/logo.png" className="logomain" alt="" />
            </div>
          </Link>
        </MDBNavbarBrand>

        <MDBNavbarToggler
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setOpenBasic(!openBasic)}
        >
          <MDBIcon icon="bars" fas style={{ color: "white" }} />
        </MDBNavbarToggler>

        <MDBCollapse navbar open={openBasic} style={{ color: "white" }}>
          <MDBNavbarNav
            className={isMobile ? "justify-content-start align-items-start" : "justify-content-end"}
            style={{ marginTop: "20px" }}
          >
            <MDBNavbarItem style={{ marginRight: "10px", marginTop: "5px" }}>
              <MDBNavbarLink active aria-current="page" href="#">
                <Link to="/services" className="links">
                  Services
                </Link>
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem style={{ marginRight: "10px", marginTop: "5px" }}>
              <MDBNavbarLink active aria-current="page" href="">
                <Link to="/packages" className="links">
                  Packages
                </Link>
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem style={{ marginRight: "10px", marginTop: "5px" }}>
              <MDBNavbarLink active aria-current="page" href="#">
                <Link to="/about-viewers" className="links">
                  About Viewers
                </Link>
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem style={{ marginRight: "20px", marginTop: "5px" }}>
              <MDBNavbarLink active aria-current="page" href="#">
                <Link to="/contact" className="links">
                  Contact Us
                </Link>
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem style={{ marginRight: "20px", marginTop: "5px" }}>
              <MDBNavbarLink active aria-current="page" href="#">
                <Link to="/about-us" className="links">
                  About Us
                </Link>
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem style={{ marginRight: "10px", marginTop: "7px" }}>
              <Link to="/viewer-details">
                <MDBBtn style={{ backgroundColor: "#ff4e00", color: "white" }}>
                  Become a Viewer
                </MDBBtn>
              </Link>
            </MDBNavbarItem>

            {reg === false ? (
              <MDBNavbarItem style={{ marginRight: "20px", marginTop: "7px" }}>
                <Link to="/register">
                  <MDBBtn style={{ backgroundColor: "#ff4e00", color: "white" }}>
                    Login / Register
                  </MDBBtn>
                </Link>
              </MDBNavbarItem>
            ) : (
              <MDBNavbarItem style={{ marginRight: "20px", marginTop: "7px" }}>
                <MDBBtn
                  onClick={handlelogout}
                  style={{ backgroundColor: "#ff4e00", color: "white" }}
                >
                  Logout
                </MDBBtn>
              </MDBNavbarItem>
            )}
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
}
