import React, { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBInput,
  MDBTextArea,
  MDBSpinner,
} from "mdb-react-ui-kit";
import Lognavbar from "../Components/lognavbar";
import Select from "react-select";
import axios from "axios";
import Footer from "./footer";
import Navbar from "../Components/navbar";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function PropertySearch() {
  const [packagename, setPackagename] = useState("");
  const [price, setprice] = useState("");

  useEffect(() => {
    document.body.style.fontFamily = "bahnschrift";
    document.body.style.backgroundColor = "#000000";

    const pathname = window.location.pathname.split("/").pop();

    const pack = pathname.replace("-", " ");
    console.log(pack);
    setPackagename(
      pathname.replace("-", " ") === "property search" ? "Property Search" : ""
    );
    const urlParams = new URLSearchParams(window.location.search);
    const price1 = urlParams.get("price");
    setprice(price1);
  }, []);

  console.log(packagename);
  const [formData, setFormData] = useState({
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
    packagename: "",
    promo: "",
    price: price,
    A_email:Cookies.get("A_email"),

    userId:localStorage.getItem("ID"),
  });
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    document.body.style.fontFamily = "bahnschrift";
    document.body.style.backgroundColor = "#000000";
  }, []);

  const options = [
    { value: "sixmonths", label: "6 Months" },
    { value: "year", label: "1 Year" },
    { value: "yearplus", label: "More than a year" },
  ];

  const option1 = [
    { value: "Email", label: "Email" },
    { value: "chat", label: "Chatbox" },
  ];

  const option2 = [
    { value: "Renting", label: "Renting" },
    { value: "Buying", label: "Buying" },
  ];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      color: "white",
      backgroundColor: "#000000",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#007BFF" : "#FFFFFF",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "white",
    }),
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData((prev) => ({
      ...prev,
      [name]: selectedOption.value,
    }));
  };

  const handlePackage = async (e) => {
    e.preventDefault();

    const userId = localStorage.getItem("ID");
    const A_email=await Cookies.get("A_email");

    if (userId) {
      try {
        setSubmit(true);

        const updatedFormData = {
          ...formData,
          packagename: packagename,
          userId: userId,
          price: price,
          A_email:A_email
        };

        console.log("This is formData", updatedFormData);

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/asare/packages`,
          updatedFormData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const responseData = response.data;

        if (responseData.message === "success") {
          window.location.href = responseData.sessionUrl;
        }

        // Reset form data and submission state
        setFormData({
          q1: "",
          q2: "",
          q3: "",
          q4: "",
          q5: "",
          promo: "",
        });
        setSubmit(false);
      } catch (error) {
        console.error("Error:", error.response.data.message);

        toast.error(error.response.data.message);
      }
    } else {
      toast.error("Login To Your Account First !");
    }
  };

  return (
    <div>
      {Cookies.get("Id") ? <Navbar /> : <Lognavbar />}
      <ToastContainer />
      <MDBContainer
        fluid
        className="d-flex align-items-center justify-content-center bg-image"
        style={{ minHeight: "100vh" }}
      >
        <div className="mask gradient-custom-3"></div>
        <div className="m-5" style={{ width: "400px" }}>
          <h3 style={{ color: "white", marginTop: "5%" }}>Property Search</h3>
          <form onSubmit={handlePackage}>
            <div className="mb-4">
              <label
                htmlFor="q1"
                style={{ textAlign: "left", display: "block", color: "white" }}
              >
                Where do you plan on living? (country/city)
              </label>
              <MDBInput
                size="lg"
                id="q1"
                type="text"
                style={{ color: "white" }}
                name="q1"
                value={formData.q1}
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="q6"
                style={{ textAlign: "left", display: "block", color: "white" }}
              >
                Do you plan on
              </label>
              <Select
                id="q6"
                name="q6"
                options={option2}
                isMulti={false}
                required
                styles={customStyles}
                placeholder="Select Answer"
                onChange={handleSelectChange}
              />
            </div>

            {formData.q6 === "Renting" ? (
              <>
                <div className="mb-4">
                  <label
                    htmlFor="q2"
                    style={{
                      textAlign: "left",
                      display: "block",
                      color: "white",
                    }}
                  >
                    What is your allocated budget?
                  </label>
                  <MDBInput
                    size="lg"
                    id="q2"
                    type="text"
                    style={{ color: "white" }}
                    name="q2"
                    value={formData.q2}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="q3"
                    style={{
                      textAlign: "left",
                      display: "block",
                      color: "white",
                    }}
                  >
                    How long are you looking to rent for?
                  </label>
                  <Select
                    id="q3"
                    name="q3"
                    options={options}
                    isMulti={false}
                    required
                    styles={customStyles}
                    placeholder="Select Answer"
                    onChange={handleSelectChange}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="mb-4">
                  <label
                    htmlFor="q7"
                    style={{
                      textAlign: "left",
                      display: "block",
                      color: "white",
                    }}
                  >
                    What is the price range you’re looking at?

                  </label>
                  <MDBInput
                    size="lg"
                    id="q7"
                    type="text"
                    style={{ color: "white" }}
                    name="q7"
                    value={formData.q7}
                    onChange={handleInputChange}
                  />
                </div>
              </>
            )}

            <div className="mb-4">
              <label
                htmlFor="q4"
                style={{ textAlign: "left", display: "block", color: "white" }}
              >
                Tell us your prefrences and we will do our best to find you
                properties that match them
              </label>
              <MDBTextArea
                size="lg"
                id="q4"
                type="text"
                style={{ color: "white" }}
                name="q4"
                value={formData.q4}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="q5"
                style={{ textAlign: "left", display: "block", color: "white" }}
              >
                How do you want us communicating with you?
              </label>
              <Select
                id="q5"
                name="q5"
                options={option1}
                isMulti={false}
                required
                styles={customStyles}
                placeholder="Select Answer"
                onChange={handleSelectChange}
              />
            </div>

            {formData.q5 === "whatsapp" ? (
              <div className="mb-4">
                <label
                  htmlFor="form1"
                  style={{
                    textAlign: "left",
                    display: "block",
                    color: "white",
                  }}
                >
                  Add Mobile Number
                </label>
                <MDBInput
                  size="lg"
                  id="form1"
                  type="text"
                  style={{ color: "white" }}
                  name="q5text"
                  value={formData.q5text}
                  onChange={handleInputChange}
                />
              </div>
            ) : formData.q5 === "Email" ? (
              <div className="mb-4">
                <label
                  htmlFor="form1"
                  style={{
                    textAlign: "left",
                    display: "block",
                    color: "white",
                  }}
                >
                  Add Email
                </label>
                <MDBInput
                  size="lg"
                  id="form1"
                  type="text"
                  style={{ color: "white" }}
                  name="q5text"
                  value={formData.q5text}
                  onChange={handleInputChange}
                />
              </div>
            ) : (
              ""
            )}
            <div className="mb-4">
              <label
                htmlFor="promo"
                style={{ textAlign: "left", display: "block", color: "white" }}
              >
                Promo Code?
              </label>
              <MDBInput
                size="lg"
                id="promo"
                type="text"
                style={{ color: "white" }}
                name="promo"
                value={formData.promo}
                onChange={handleInputChange}
              />
            </div>
            <MDBBtn
              className="mb-4 w-100 gradient-custom-4"
              size="lg"
              style={{
                borderRadius: "0",
                color: "black",
                backgroundColor: "white",
              }}
              type="submit"
            >
              {submit ? <MDBSpinner /> : <span>Pay Now</span>}
            </MDBBtn>
          </form>
        </div>
      </MDBContainer>
      <Footer />
    </div>
  );
}
