import React, { useEffect } from 'react';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
    MDBCard,
    MDBCardImage,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardFooter,
    MDBBtn,
    MDBRow,
    MDBCol
} from 'mdb-react-ui-kit';
import Navbar from '../Components/navbar';

export default function Loghome() {

  useEffect(()=>{
    document.body.style.fontFamily="bahnschrift";
    document.body.style.backgroundColor="#000000";
  },[])

  return (
    <div>
        <Navbar />

        <div className='head'>
            <h1 className='mainheading'>Shop</h1>
        </div>

    <center>
    <MDBRow className='row-cols-1 row-cols-md-4 g-4' style={{margin:"30px",width:"80%"}}>
      <MDBCol>
        <MDBCard className='h-100 register-card' style={{border:"1px solid white"}}>
          <MDBCardImage
            src='../Assets/shop/shop1.png'
            alt='...'
            style={{width:"100%",height:"300px"}}
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle style={{color:"white"}}>Title Here</MDBCardTitle>
            <MDBCardText>
            <h4 style={{color:"white",fontWeight:"bolder"}}>$100</h4>
            <p style={{color:"white"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            <MDBBtn style={{backgroundColor:"white",color:"black"}}><i className='fa fa-shopping-cart' style={{marginRight:"10px"}}></i> Add to Cart</MDBBtn>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100 register-card' style={{border:"1px solid white"}}>
          <MDBCardImage
            src='../Assets/shop/shop2.png'
            alt='...'
            style={{width:"100%",height:"300px"}}
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle style={{color:"white"}}>Title Here</MDBCardTitle>
            <MDBCardText>
            <h4 style={{color:"white",fontWeight:"bolder"}}>$100</h4>
            <p style={{color:"white"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            <MDBBtn style={{backgroundColor:"white",color:"black"}}><i className='fa fa-shopping-cart' style={{marginRight:"10px"}}></i> Add to Cart</MDBBtn>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100 register-card' style={{border:"1px solid white"}}>
          <MDBCardImage
            src='../Assets/shop/shop5.png'
            alt='...'
            style={{width:"100%",height:"300px"}}
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle style={{color:"white"}}>Title Here</MDBCardTitle>
            <MDBCardText>
            <h4 style={{color:"white",fontWeight:"bolder"}}>$100</h4>
            <p style={{color:"white"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            <MDBBtn style={{backgroundColor:"white",color:"black"}}><i className='fa fa-shopping-cart' style={{marginRight:"10px"}}></i> Add to Cart</MDBBtn>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100 register-card' style={{border:"1px solid white"}}>
          <MDBCardImage
            src='../Assets/shop/shop4.png'
            alt='...'
            style={{width:"100%",height:"300px"}}
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle style={{color:"white"}}>Title Here</MDBCardTitle>
            <MDBCardText>
             <h4 style={{color:"white",fontWeight:"bolder"}}>$100</h4>
             <p style={{color:"white"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
             <MDBBtn style={{backgroundColor:"white",color:"black"}}><i className='fa fa-shopping-cart' style={{marginRight:"10px"}}></i> Add to Cart</MDBBtn>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100 register-card' style={{border:"1px solid white"}}>
          <MDBCardImage
            src='../Assets/shop/shop1.png'
            alt='...'
            style={{width:"100%",height:"300px"}}
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle style={{color:"white"}}>Title Here</MDBCardTitle>
            <MDBCardText>
            <h4 style={{color:"white",fontWeight:"bolder"}}>$100</h4>
            <p style={{color:"white"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            <MDBBtn style={{backgroundColor:"white",color:"black"}}><i className='fa fa-shopping-cart' style={{marginRight:"10px"}}></i> Add to Cart</MDBBtn>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100 register-card' style={{border:"1px solid white"}}>
          <MDBCardImage
            src='../Assets/shop/shop2.png'
            alt='...'
            style={{width:"100%",height:"300px"}}
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle style={{color:"white"}}>Title Here</MDBCardTitle>
            <MDBCardText>
            <h4 style={{color:"white",fontWeight:"bolder"}}>$100</h4>
            <p style={{color:"white"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            <MDBBtn style={{backgroundColor:"white",color:"black"}}><i className='fa fa-shopping-cart' style={{marginRight:"10px"}}></i> Add to Cart</MDBBtn>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100 register-card' style={{border:"1px solid white"}}>
          <MDBCardImage
            src='../Assets/shop/shop5.png'
            alt='...'
            style={{width:"100%",height:"300px"}}
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle style={{color:"white"}}>Title Here</MDBCardTitle>
            <MDBCardText>
            <h4 style={{color:"white",fontWeight:"bolder"}}>$100</h4>
            <p style={{color:"white"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            <MDBBtn style={{backgroundColor:"white",color:"black"}}><i className='fa fa-shopping-cart' style={{marginRight:"10px"}}></i> Add to Cart</MDBBtn>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100 register-card' style={{border:"1px solid white"}}>
          <MDBCardImage
            src='../Assets/shop/shop4.png'
            alt='...'
            style={{width:"100%",height:"300px"}}
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle style={{color:"white"}}>Title Here</MDBCardTitle>
            <MDBCardText>
             <h4 style={{color:"white",fontWeight:"bolder"}}>$100</h4>
             <p style={{color:"white"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
             <MDBBtn style={{backgroundColor:"white",color:"black"}}><i className='fa fa-shopping-cart' style={{marginRight:"10px"}}></i> Add to Cart</MDBBtn>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
    </center>
    </div>
  )
}
