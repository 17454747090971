import React, { useEffect, useState } from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
} from "mdb-react-ui-kit";
import {
  MDBRow,
  MDBCol,
  MDBCarousel,
  MDBCarouselItem,
  MDBCarouselCaption,
} from "mdb-react-ui-kit";
import Lognavbar from "../Components/lognavbar";
import Cookies from "js-cookie";
import Faq from "react-faq-component";
import Navbar from "../Components/navbar";
import "react-step-progress-bar/styles.css";
import Footer from "./footer";
import { Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export default function ViewerDetails() {
  useEffect(() => {
    document.body.style.fontFamily = "bahnschrift";
    document.body.style.backgroundColor = "#000000";
  }, []);

  const [data, setData] = useState({
    title: "FAQ (How it works)",
    rows: [
      {
        title: "Lorem ipsum dolor sit amet,",
        content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed tempor sem. Aenean vel turpis feugiat,
          ultricies metus at, consequat velit. Curabitur est nibh, varius in tellus nec, mattis pulvinar metus.
          In maximus cursus lorem, nec laoreet velit eleifend vel. Ut aliquet mauris tortor, sed egestas libero interdum vitae.
          Fusce sed commodo purus, at tempus turpis.`,
      },
      {
        title: "Nunc maximus, magna at ultricies elementum",
        content:
          "Nunc maximus, magna at ultricies elementum, risus turpis vulputate quam, vitae convallis ex tortor sed dolor.",
      },
      {
        title: "Curabitur laoreet, mauris vel blandit fringilla",
        content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
          Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
          Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
          Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
      },
      {
        title: "What is the package version",
        content: <p>current version is 1.2.1</p>,
      },
    ],
  });

  const fetchFaqs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/getfaqs`
      );

      // Assuming response.data.data is an array of FAQ objects
      setData({
        ...data,
        rows: response.data.data.map((faq) => ({
          title: faq.question,
          content: faq.answer,
        })),
      });
    } catch (error) {
      console.error("Error fetching FAQs:", error);
    }
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  const styles = {
    bgColor: "black",
    titleTextColor: "#ff4e00",
    rowTitleColor: "white",
    rowContentColor: "white",
    arrowColor: "#ff4e00",
    textAlign: "left",
  };

  const config = {
    animate: true,
    tabFocus: true,
  };
  const [pacakgesdescription, setpackagesdescription] = useState("");

  const fetchdata1 = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/admin/getviewerjobdescription`
      );
      console.log("setpackagesdescription", response.data);
      setpackagesdescription(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchdata1();
  }, []);

  const [basicViewingDescriptions, setBasicViewingDescriptions] = useState([]);

  useEffect(() => {
    if (pacakgesdescription) {
      const filteredDescriptions = pacakgesdescription.filter(
        (package1) => package1.name === "Basic Viewing"
      );

      console.log(filteredDescriptions);
      setBasicViewingDescriptions(
        filteredDescriptions[0].description
          .split("\n")
          .filter((point) => point.trim() !== "")
      );
    }
  }, [pacakgesdescription]);

  console.log(basicViewingDescriptions);


  const [proViewingDescriptions, setProViewingDescriptions] = useState([]);

  useEffect(() => {
    if (pacakgesdescription) {
      const filteredDescriptions = pacakgesdescription.filter(
        (package1) => package1.name === "Pro Viewing"
      );

      console.log(filteredDescriptions);
      setProViewingDescriptions(
        filteredDescriptions[0].description
          .split("\n")
          .filter((point) => point.trim() !== "")
      );
    }
  }, [pacakgesdescription]);
  return (
    <div>
      {Cookies.get("Id") ? <Navbar /> : <Lognavbar />}

      <MDBCarousel showIndicators showControls fade>
        <MDBCarouselItem itemId={1}>
          <img src="../Assets/images/3.png" alt="..." className="slider" />
          <MDBCarouselCaption>
            <h1 className="title">EARN MONEY VIEWING PROPERTY NEAR YOU</h1>
            <p>
              Attend property viewing Just around your neighbourhood, earn extra
              income
            </p>
          </MDBCarouselCaption>
        </MDBCarouselItem>

        <MDBCarouselItem itemId={2}>
          <img src="../Assets/images/4.png" alt="..." className="slider" />
          <MDBCarouselCaption>
            <h1 className="title">EARN MONEY VIEWING PROPERTY NEAR YOU</h1>
            <p>
              Attend property viewing Just around your neighbourhood, earn extra
              income
            </p>
          </MDBCarouselCaption>
        </MDBCarouselItem>

        <MDBCarouselItem itemId={3}>
          <img src="../Assets/images/5.png" alt="..." className="slider" />
          <MDBCarouselCaption>
            <h1 className="title">EARN MONEY VIEWING PROPERTY NEAR YOU</h1>
            <p style={{ color: "white" }}>
              Attend property viewing Just around your neighbourhood, earn extra
              income
            </p>
          </MDBCarouselCaption>
        </MDBCarouselItem>

        <MDBCarouselItem itemId={3}>
          <img src="../Assets/images/6.png" alt="..." className="slider" />
          <MDBCarouselCaption>
            <h1 className="title">EARN MONEY VIEWING PROPERTY NEAR YOU</h1>
            <p style={{ color: "white" }}>
              Attend property viewing Just around your neighbourhood, earn extra
              income
            </p>
          </MDBCarouselCaption>
        </MDBCarouselItem>
      </MDBCarousel>

      <div>
        <h2 style={{ marginTop: "4%", color: "#ff4e00", fontSize: "35px" }}>
          You Can Earn
        </h2>

        <center>
          <MDBRow
            className="row-cols-1 row-cols-md-2 g-4"
            style={{ width: "80%", marginTop: "2%" }}
          >
            <MDBCol>
              <MDBCard
                className="h-100"
                style={{ backgroundColor: "#1a2229", borderRadius: "30px" }}
              >
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Basic Viewing
                  </MDBCardTitle>
                  <MDBCardText>
                    <div>
                      {basicViewingDescriptions &&
                        basicViewingDescriptions.map((desc) => {
                          return (
                            <>
                              <div style={{ display: "flex" }}>
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    color="#ff4e00"
                                    style={{ width: "20px", height: "20px",marginTop:"5px" }}
                                  />
                                <p
                                  style={{
                                    marginLeft: "5px",
                                    textAlign: "justify",
                                    marginTop: "5px",
                                    color: "white",
                                  }}
                                >
                                  {desc}
                                </p>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol>
              <MDBCard
                className="h-100"
                style={{ backgroundColor: "#1a2229", borderRadius: "30px" }}
              >
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Pro Viewing
                  </MDBCardTitle>
                  <MDBCardText>
                  <div>
                      {proViewingDescriptions &&
                        proViewingDescriptions.map((desc) => {
                          return (
                            <>
                              <div style={{ display: "flex" }}>
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    color="#ff4e00"
                                    style={{ width: "20px", height: "20px",marginTop:"5px" }}
                                  />
                                <p
                                  style={{
                                    marginLeft: "5px",
                                    textAlign: "justify",
                                    marginTop: "5px",
                                    color: "white",
                                  }}
                                >
                                  {desc}
                                </p>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </center>

        <h2 style={{ marginTop: "4%", color: "#ff4e00", fontSize: "35px" }}>
          How to earn money with Asare Viewing?
        </h2>
        <center>
          <div style={{ textAlign: "left", color: "white", width: "80%" }}>
            <p>1. Accept the viewing Request</p>
            <p>
              2. Arrange viewing date with with estate agent or property owner
            </p>
            <p>3. Attend Viewing</p>
            <p>4. Submit all information</p>
            <p>
              Its that easy to earn money helping people view their properties
            </p>
            <Link to="/viewer-registration">
              <MDBBtn style={{ color: "white", backgroundColor: "#ff4e00" }}>
                Become a Viewer
              </MDBBtn>
            </Link>
          </div>
        </center>
      </div>

      <h2 style={{ marginTop: "4%", color: "#ff4e00", fontSize: "35px" }}>
        Join Asare Viewing & Enjoy the benifits
      </h2>
      <center>
        <div style={{ width: "80%", marginTop: "2%" }}>
          <MDBRow>
            <MDBCol md={4}>
              <div style={{ display: "flex" }}>
                <img
                  src="../Assets/money.png"
                  alt=""
                  style={{ width: "50px", height: "50px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "20px" }}>
                  <p style={{ color: "#ff4e00" }}>Earn as you view</p>
                  <p style={{ color: "white", marginTop: "-14px" }}>
                    The more viewing you attend the more money you could make.
                    You only earn a per viewing basis.{" "}
                  </p>
                </div>
              </div>
            </MDBCol>
            <MDBCol md={4}>
              <div style={{ display: "flex" }}>
                <img
                  src="../Assets/dollar.png"
                  alt=""
                  style={{ width: "50px", height: "50px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "20px" }}>
                  <p style={{ color: "#ff4e00" }}>Get Paid Weekly</p>
                  <p style={{ color: "white", marginTop: "-14px" }}>
                    You Get paid on a weekly basis for the week just gone.
                  </p>
                </div>
              </div>
            </MDBCol>
            <MDBCol md={4}>
              <div style={{ display: "flex" }}>
                <img
                  src="../Assets/work.png"
                  alt=""
                  style={{ width: "50px", height: "50px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "20px" }}>
                  <p style={{ color: "#ff4e00" }}>Flexibility & Control</p>
                  <p style={{ color: "white", marginTop: "-14px" }}>
                  Arrange viewing on your own schedule. The more proficient you become, the more viewing 
                  opportunities you get.
                  </p>
                </div>
              </div>
            </MDBCol>
          </MDBRow>

          <MDBRow style={{ marginTop: "2%" }}>
            <MDBCol md={4}>
              <div style={{ display: "flex" }}>
                <img
                  src="../Assets/distance.png"
                  alt=""
                  style={{ width: "50px", height: "50px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "20px" }}>
                  <p style={{ color: "#ff4e00" }}>Short Distance only</p>
                  <p style={{ color: "white", marginTop: "-14px" }}>
                    Arrange viewing in your own local area, but you can travel
                    as far as you can.
                  </p>
                </div>
              </div>
            </MDBCol>
            <MDBCol md={4}>
              <div style={{ display: "flex" }}>
                <img
                  src="../Assets/upfront.png"
                  alt=""
                  style={{ width: "50px", height: "50px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "20px" }}>
                  <p style={{ color: "#ff4e00" }}>No upfront cost</p>
                  <p style={{ color: "white", marginTop: "-14px" }}>
                    Join asare viewing for free and start work with no upfront
                    charges
                  </p>
                </div>
              </div>
            </MDBCol>
          </MDBRow>

          <center>
            <Link to="/viewer-registration">
              <MDBBtn
                style={{
                  color: "white",
                  backgroundColor: "#ff4e00",
                  marginTop: "3%",
                }}
              >
                Get Started
              </MDBBtn>
            </Link>
          </center>
        </div>
      </center>

      <h2 style={{ marginTop: "4%", color: "#ff4e00", fontSize: "35px" }}>
        We have got you covered
      </h2>

      <center>
        <div style={{ width: "80%", marginTop: "2%" }}>
          <MDBRow style={{ marginTop: "2%" }}>
            <MDBCol md={4}>
              <div style={{ display: "flex" }}>
                <img
                  src="../Assets/support.png"
                  alt=""
                  style={{ width: "50px", height: "50px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "20px" }}>
                  <p style={{ color: "#ff4e00" }}>Our Support</p>
                  <p style={{ color: "white", marginTop: "-14px" }}>
                    We have your back every step. Our team is here to assist you
                    with any problem you might face along the way.
                  </p>
                </div>
              </div>
            </MDBCol>
            <MDBCol md={4}>
              <div style={{ display: "flex" }}>
                <img
                  src="../Assets/presentation.png"
                  alt=""
                  style={{ width: "50px", height: "50px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "20px" }}>
                  <p style={{ color: "#ff4e00" }}>Our Training</p>
                  <p style={{ color: "white", marginTop: "-14px" }}>
                    We'll provide you with all the neccessary training to
                    provide amazing viewing to your seekers.
                  </p>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </div>

        <center>
          <Link to="/viewer-registration">
            <MDBBtn
              style={{
                color: "white",
                backgroundColor: "#ff4e00",
                marginTop: "3%",
              }}
            >
              Join Asare viewing
            </MDBBtn>
          </Link>
        </center>
        <div style={{ width: "80%", marginTop: "4%", textAlign: "left !important" }}>
          <Faq data={data} styles={styles} config={config} />
        </div>
      </center>
      <Footer />
    </div>
  );
}
