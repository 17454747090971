import React, { useEffect } from 'react';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import Lognavbar from '../Components/lognavbar';
import { MDBBtn } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import Navbar from '../Components/navbar';
import Footer from './footer';

export default function Success() {

  useEffect(()=>{
    document.body.style.fontFamily="bahnschrift";
    document.body.style.backgroundColor="#000000";
  },[])

  return (
    <div className='about'>
        {Cookies.get("Id")? <Navbar/> : <Lognavbar />}
        <div className='head'>
          <center>
            <img src="../Assets/success.gif" alt="" className='emailicon'/>
            <h4 style={{color:"green",marginTop:"-10px",marginBottom:"20px"}} className='emailhead'>Payment Successful</h4>
            <Link to="/"><MDBBtn style={{color:'white',backgroundColor:"#ff4e00"}}>Back to Home</MDBBtn></Link>
          </center>
        </div>
        <Footer/>
    </div>
  )
}
