import React, { useEffect } from 'react';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import Lognavbar from '../Components/lognavbar';
import { MDBBtn } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import Footer from './footer';

export default function Verified() {

  useEffect(()=>{
    document.body.style.fontFamily="bahnschrift";
    document.body.style.backgroundColor="#000000";
  },[])

  return (
    <div className='about'>
        <Lognavbar />
        <div className='head'>
          <center>
            <img src="../Assets/success.gif" alt="" className='emailicon'/>
            <h4 style={{color:"green",marginTop:"-10px",marginBottom:"20px"}} className='emailhead'>Congratulation! Your email has been verified</h4>
            <Link to="/login"><MDBBtn style={{color:'white',backgroundColor:"#ff4e00"}}>Login</MDBBtn></Link>
          </center>
        </div>
        <Footer/>
    </div>
  )
}
