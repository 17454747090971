import './App.css';
import {Switch,Route} from 'react-router-dom';
import Home from './Pages/Home';
import Register from './Pages/register';
import Login from './Pages/login';
import About from './Pages/about';
import Loghome from './Pages/Loghome';
import Services from './Pages/services';
import Packages from './Pages/packages';
import Contact from './Pages/contact';
import ViewerRegister from './Pages/viewerRegister';
import Subscribe from './Pages/packageForm';
import ViewerTerms from './Pages/viewerterms';
import SeekersTerms from './Pages/seekerterms';
import ViewerDetails from './Pages/viewerDetails';
import EmailSent from './Pages/emailsent';
import Verified from './Pages/verified';
import Success from './Pages/success';
import PropertySearch from './Pages/propertySearch';
import Aboutus from './Pages/Aboutus';

function App() {
  return (
    <div className="App">
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/register" component={Register}/>
          <Route exact path="/login" component={Login}/>
          <Route exact path="/sent" component={EmailSent}/>
          <Route exact path="/verified" component={Verified}/>
          <Route exact path="/viewer-details" component={ViewerDetails}/>
          <Route exact path="/services" component={Services}/>
          <Route exact path="/packages" component={Packages}/>
          <Route exact path="/about-viewers" component={About}/>
          <Route exact path="/contact" component={Contact}/>
          <Route exact path="/viewer-registration" component={ViewerRegister}/>
          <Route exact path="/subscribe" component={Subscribe}/>
          <Route exact path="/home" component={Loghome}/>
          <Route exact path="/viewer-terms" component={ViewerTerms}/>
          <Route exact path="/seekers-terms" component={SeekersTerms}/>
          <Route exact path="/success" component={Success}/>
          <Route exact path="/property-search" component={PropertySearch}/>
          <Route exact path="/about-us" component={Aboutus}/>

          
        </Switch>
    </div>
  );
}

export default App;
