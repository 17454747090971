import React, { useState, useEffect } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const MapContainer = ({ google }) => {
    const [markers, setMarkers] = useState([
        { lat: 53.8008, lng: -1.5491 },
        { lat: 53.7467, lng: -0.3390 }, 
        { lat: 53.3811, lng: -1.4701 },
        { lat: 53.9591, lng: -1.0812 },  
        { lat: 53.7945, lng: -1.7539 },
        { lat: 53.5228, lng: -1.1286 },
        { lat: 53.4084, lng: -2.9916 },
        { lat: 53.4839, lng: -2.2446 },
        { lat: 52.9228, lng: -1.4762 },
        { lat: 51.5074, lng: -0.1278 }
      ]);

  const mapStyles = {
    width: '600px',
    height: '400px'
  };

  const mapStyles1 = {
    width: '94%',
    height: '400px'
  };

  return (
    <Map
      google={google}
      zoom={5}
      style={window.innerWidth < 600 ? mapStyles1 : mapStyles}
      initialCenter={{ lat: 53.7945, lng: -1.7539 } }
    >
      {markers.map((marker, index) => (
        <Marker key={index} position={{ lat: marker.lat, lng: marker.lng }} />
      ))}
    </Map>
  );
};

// Wrap the MapContainer component with GoogleApiWrapper HOC
const WrappedMapContainer = GoogleApiWrapper({
  apiKey: 'AIzaSyD1ajp6njHPa2VFLQkLL6DkP8crqR20ioI' // Replace with your own Google Maps API key
})(MapContainer);

export default WrappedMapContainer;
