import React, { useEffect, useState } from 'react';
import { MDBBtn, MDBContainer, MDBInput, MDBRow, MDBCol,MDBCheckbox, MDBSpinner } from 'mdb-react-ui-kit';
import Lognavbar from '../Components/lognavbar';
import Select from 'react-select';
import axios from 'axios';
import Footer from './footer';
import Navbar from '../Components/navbar';
import Cookies from 'js-cookie';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Subscribe() {
  const [packagename,setPackagename]=useState('');
  const [price,setprice]=useState('');

  useEffect(() => {
    document.body.style.fontFamily="bahnschrift";
    document.body.style.backgroundColor = '#000000';

    const urlParams = new URLSearchParams(window.location.search);
    const paramValue = urlParams.get('package');
    const price1 = urlParams.get('price');

    if(price1)
    {
      setprice(price1)
    }

    if (paramValue) {
      setPackagename(paramValue === "basic" ? "Basic Viewing" : paramValue === "pro" ? "Pro Viewing" : "");
    
    } else {
      setPackagename(""); 
    }
    console.log(paramValue);
  }, []);

  const initialForm={
    q1: '',
    q2: '',
    q3:'',
    q4:'',
    q5:'',
    promo:'',
    q1url:'',
    q1postcode:'',
    q1city:'',
    q1country:'',
    street1:'',
    street2:'',
    street3:'',
    city:'',
    postcode:'',
    country:'',
    q2specify:'',
    q3part1:'',
    q3part2:'',
    q4date:'',
    q5text:'',
    packagename:packagename,
price:price,
A_email:Cookies.get("A_email"),

    userId:localStorage.getItem("ID"),
  }



  const [formData, setFormData] = useState(initialForm);
  const [submit,setSubmit]=useState(false);
  const [error,setError]=useState('');

  console.log(formData);
  const options = [
    { value: 'Internet', label: 'On the Internet' },
    { value: 'address', label: 'I know the property address' },
  ];

  const option1 = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ];
  
  const option3 = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ];

  const option2 = [
    { value: 'email', label: 'Email' },
    { value: 'chat', label: 'ChatBox' },
  ];

  const handleQ1 = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : ''; 

    setFormData((prevFormData) => ({
      ...prevFormData,
      q1: selectedValue, 

    }));
  };

  const handleQ2 = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : ''; 

    setFormData((prevFormData) => ({
      ...prevFormData,
      q2: selectedValue, 
    }));
  };

  const handleQ3 = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : ''; 

    setFormData((prevFormData) => ({
      ...prevFormData,
      q3: selectedValue, 
    }));
  };

  const handleQ4 = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : ''; 

    setFormData((prevFormData) => ({
      ...prevFormData,
      q4: selectedValue, 
    }));
  };

  const handleQ4date = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : ''; 

    setFormData((prevFormData) => ({
      ...prevFormData,
      q4date: selectedValue, 
    }));
  };

  const handleQ5 = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : ''; 

    setFormData((prevFormData) => ({
      ...prevFormData,
      q5: selectedValue, 
    }));
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      color: 'white',
      backgroundColor: '#000000', 
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? '#007BFF' : '#FFFFFF',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: 'white',
    }),
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  async function handlePackage(e) {
    e.preventDefault();

    const userId = await localStorage.getItem("ID");


    const A_email=await Cookies.get("A_email");
    if (userId) {
        try {
            setSubmit(true);

            const updatedFormData = {
                ...formData,
                packagename: packagename,
                userId: userId,
                price:price,
                A_email:A_email
            };

            console.log("This is formData", updatedFormData);

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/asare/packages`, updatedFormData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const responseData = response.data;
            if (responseData.message === 'success') {
                window.location.href = responseData.sessionUrl;
            }

            setFormData(initialForm);
            setSubmit(false);
        } catch (error) {
            console.error('Error:', error);
            toast.error(error.response.data.message);
        }
    } else {
        errorNotify();
    }
}
  const errorNotify = () => {
    toast.error('Login to your account first', {
      position: toast.POSITION.TOP_CENTER 
    });
  };

  return (
    <div>
      {Cookies.get("Id")? <Navbar/> : <Lognavbar />}
      <ToastContainer/>
      <MDBContainer fluid className='d-flex align-items-center justify-content-center bg-image' style={{ minHeight: '100vh' }}>
        <div className='mask gradient-custom-3'></div>
        <div className='m-5' style={{ width: '400px' }}>
          <h3 style={{ color: 'white', marginTop: '5%' }}>{packagename=="basic"?"Basic Viewing Package": packagename=="pro"?"Pro Viewing Package":""}</h3>
          <div className="mb-4">
            <label htmlFor="propertySource" style={{ textAlign: 'left', display: 'block', color: 'white' }}>Where did you find this Property?</label>
            <Select
              id="multi-select"
              options={options}
              isMulti={false}
              required
              onChange={handleQ1}
              styles={customStyles}
              placeholder="Select Answer"
            />
          </div>
          {formData.q1=="Internet"?(
            <div>
              <div className="mb-4">
                <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Enter URL</label>
                <MDBInput size='lg' id='form1' type='url'style={{color:"white"}}
                  name='q1url'
                  value={formData.q1url}
                  onChange={handleInputChange}
                />
              </div>
              <MDBRow>
                <MDBCol md={6}>
                  <div className="mb-4">
                    <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Post Code</label>
                    <MDBInput size='lg' id='form1' type='url'style={{color:"white"}}
                      name='q1postcode'
                      value={formData.q1postcode}
                      onChange={handleInputChange}
                    />
                  </div>
                </MDBCol>
                <MDBCol md={6}>
                  <div className="mb-4">
                    <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>City</label>
                    <MDBInput size='lg' id='form1' type='url'style={{color:"white"}}
                      name='q1city'
                      value={formData.q1city}
                      onChange={handleInputChange}
                    />
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow md={12}>
                <div className="mb-4">
                  <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Country</label>
                  <MDBInput size='lg' id='form1' type='url'style={{color:"white"}}
                    name='q1country'
                    value={formData.q1country}
                    onChange={handleInputChange}
                  />
                </div>
              </MDBRow>
            </div>
          ): formData.q1=="address"?(
            <div>
              <MDBRow>
                <MDBCol md={6}>
                    <div className="mb-4">
                        <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Street / House No</label>
                        <MDBInput size='lg' id='form1' type='text'
                        name='street1'
                        value={formData.street1}
                        onChange={handleInputChange}
                        />
                    </div>
                </MDBCol>
                <MDBCol md={6}>
                <div className="mb-4">
                        <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Street 2</label>
                        <MDBInput size='lg' id='form1' type='text'
                        name='street2'
                        value={formData.street2}
                        onChange={handleInputChange}/>
                    </div>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol md={6}>
                    <div className="mb-4">
                        <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Street 3</label>
                        <MDBInput size='lg' id='form1' type='text'
                        name='street3'
                        value={formData.street3}
                        onChange={handleInputChange}/>
                    </div>
                </MDBCol>
                <MDBCol md={6}>
                <div className="mb-4">
                        <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>City</label>
                        <MDBInput size='lg' id='form1' type='text'
                        name='city'
                        value={formData.city}
                        onChange={handleInputChange}/>
                    </div>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol md={6}>
                    <div className="mb-4">
                        <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Post Code</label>
                        <MDBInput size='lg' id='form1' type='text'
                        name='postcode'
                        value={formData.postcode}
                        onChange={handleInputChange}/>
                    </div>
                </MDBCol>
                <MDBCol md={6}>
                <div className="mb-4">
                        <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Country</label>
                        <MDBInput size='lg' id='form1' type='text'
                        name='country'
                        value={formData.country}
                        onChange={handleInputChange}/>
                    </div>
                </MDBCol>
            </MDBRow>
            </div>
          ):("")}

          {packagename!=="Basic Viewing"?(
            <div className="mb-4">
              <label htmlFor="propertySource" style={{ textAlign: 'left', display: 'block', color: 'white' }}>
              Do you have any specific concerns about the property and or its environment you want looking 
into?
              </label>
              <Select
                id="multi-select"
                options={option1}
                required
                isMulti={false}
                onChange={handleQ2}
                styles={customStyles}
                placeholder="Select Answer"
              />
            </div>
          ):(
            ""
          )}
          
          {formData.q2=="yes"?(
            <div className="mb-4">
                <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Specify</label>
                <MDBInput size='lg' id='form1' type='text'style={{color:"white"}}
                name='q2specify'
                value={formData.q2specify}
                onChange={handleInputChange}/>
              </div>
          ):("")}

          <div className="mb-4">
            <label htmlFor="propertySource" style={{ textAlign: 'left', display: 'block', color: 'white' }}>Have you already communicated with the agent/property manager?</label>
            <Select
              id="multi-select"
              options={option1}
              isMulti={false}
              required
              onChange={handleQ3}
              styles={customStyles}
              placeholder="Select Answer"
            />
          </div>

          {formData.q3=="yes"?(
            <div>
              <div className="mb-4">
                <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Agent/Property owner contact</label>
                <MDBInput size='lg' id='form1' type='text' style={{color:"white"}}
                name='q3part1'
                value={formData.q3part1}
                onChange={handleInputChange}/>
              </div>
              <div className="mb-4">
                <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>What have you already discussed that is beneficial to the viewers?</label>
                <MDBInput size='lg' id='form1' type='text' style={{color:"white"}}
                name='q3part2'
                value={formData.q3part2}
                onChange={handleInputChange}/>
              </div>
            </div>
          ):(
            ""
          )}

          <div className="mb-4">
            <label htmlFor="propertySource" style={{ textAlign: 'left', display: 'block', color: 'white' }}>The fastest time we can get a viewing for you is within 10 working days. Is it ample time enough?</label>
            <Select
              id="multi-select"
              options={option1}
              isMulti={false}
              required
              onChange={handleQ4}
              styles={customStyles}
              placeholder="Select Answer"
            />
          </div>

          {formData.q4 === "no" ? (
    <div className="mb-4">
        <label htmlFor="viewingDate" style={{ textAlign: 'left', display: 'block', color: 'white' }}>
            Select Preferred Viewing Date
        </label>
        <Select
            id="viewing-date-select"
            options={[
                { value: '5days', label: 'Within 5 working days (40% extra charge)' },
                { value: '7days', label: 'Within 7 working days (25% extra charge)' }
            ]}
            isMulti={false}
            required
            onChange={handleQ4date}
            styles={customStyles}
            placeholder="Select Date"
        />
    </div>
) : ("")}

          <div className="mb-4">
            <label htmlFor="propertySource" style={{ textAlign: 'left', display: 'block', color: 'white' }}>How would you want the details sent to you?</label>
            <Select
              id="multi-select"
              options={option2}
              isMulti={false}
              required
              onChange={handleQ5}
              styles={customStyles}
              placeholder="Select Answer"
            />
          </div>

          {formData.q5=="whatsapp"?(
              <div className="mb-4">
                <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Add Mobile Number</label>
                <MDBInput size='lg' id='form1' type='text' style={{color:"white"}}
                name='q5text'
                value={formData.q5text}
                onChange={handleInputChange}/>
              </div>
          ):formData.q5=="email"?(
              <div className="mb-4">
                <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Add Email</label>
                <MDBInput size='lg' id='form1' type='text' style={{color:"white"}}
                name='q5text'
                value={formData.q5text}
                onChange={handleInputChange}/>
              </div>
          ):("")}

          <div className="mb-4">
              <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Enter Promo Code</label>
              <MDBInput size='lg' id='form1' type='text' style={{color:"white"}}
              name='promo'
              value={formData.promo}
              onChange={handleInputChange}/>
          </div>

          <div style={{display:"flex"}}>
              <MDBCheckbox
                  id='controlledCheckbox'
                  required
              />
              <p style={{color:"white",textAlign:"left",marginLeft:"3px"}}>Give consent for an Agent from Asare Viewing to view this property on your behalf</p>
          </div>

          <MDBBtn
            className='mb-4 w-100 gradient-custom-4'
            size='lg'
            style={{ borderRadius: '0', color: 'black', backgroundColor: 'white' }}
            onClick={handlePackage}
          >
            {submit?(
              <MDBSpinner></MDBSpinner>
            ):(
              <span>Pay Now</span>
            )}
          </MDBBtn>
        </div>
      </MDBContainer>

      <Footer />
    </div>
  );
}
