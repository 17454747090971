import React, { useEffect } from 'react';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBRow,
    MDBCol,
    MDBBtn
  } from 'mdb-react-ui-kit';
import Lognavbar from '../Components/lognavbar';
import Cookies from 'js-cookie';
import Footer from './footer';
import Navbar from '../Components/navbar';
import { Link } from 'react-router-dom';

export default function Services() {

  useEffect(()=>{
    document.body.style.fontFamily="bahnschrift";
    document.body.style.backgroundColor="#000000";
  },[])

  return (
    <div className='about'>
        {Cookies.get("Id")? <Navbar/> : <Lognavbar />}
        <div className='head'>
            <h1 className='mainheading' style={{color:"#ff4e00",marginTop:"2%"}}>Services</h1>
            <center>
            <MDBRow className='row-cols-1 row-cols-md-2 g-4 cardss'>
                <MDBCol>
                    <MDBCard className='h-100' style={{backgroundColor:"#1a2229",borderRadius:"30px"}}>
                    <MDBCardBody>
                        <center><img src="../Assets/town.png" alt="" style={{width:"100px"}}/></center>
                        <MDBCardTitle style={{marginTop:"25px",color:"white"}}>Property Viewing</MDBCardTitle>
                        <MDBCardText>
                          <div>
                            <div style={{display:"flex"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30" height="30">
                              <circle cx="12" cy="12" r="10" fill="#ff4e00" /> 
                              <path
                                fill="white"
                                d="M8.5 12.5l-2.5-2.5-1.42 1.41 3.92 3.92L17.92 8.5l-1.42-1.42z"
                              /> 
                            </svg>
                              <p style={{marginLeft:"5px",textAlign:"left",marginTop:"5px",color:"white"}}>Let our locally trained viewers arrange and attend a viewing on your behalf for any interested property.</p>
                            </div>
                            <div style={{display:"flex"}}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="23" height="23">
                              <circle cx="12" cy="12" r="10" fill="#ff4e00" /> 
                              <path
                                fill="white"
                                d="M8.5 12.5l-2.5-2.5-1.42 1.41 3.92 3.92L17.92 8.5l-1.42-1.42z"
                              /> 
                            </svg>
                              <p style={{marginLeft:"5px",textAlign:"left",marginTop:"3px",color:"white"}}>Get a thorough report back before you commit.</p>
                            </div>
                          </div>
                        </MDBCardText>
                    </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard className='h-100' style={{backgroundColor:"#1a2229",borderRadius:"30px"}}>
                    <MDBCardBody>
                        <center><img src="../Assets/house.png" alt="" style={{width:"100px"}}/></center>
                        <MDBCardTitle style={{marginTop:"25px",color:"white"}}>Property Searching</MDBCardTitle>
                        <MDBCardText>
                        <div>
                            <div style={{display:"flex"}}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="23" height="23">
                              <circle cx="12" cy="12" r="10" fill="#ff4e00" /> 
                              <path
                                fill="white"
                                d="M8.5 12.5l-2.5-2.5-1.42 1.41 3.92 3.92L17.92 8.5l-1.42-1.42z"
                              /> 
                              </svg>
                              <p style={{marginLeft:"5px",textAlign:"left",marginTop:"3px",color:"white"}}>Using UK*s leading property search engines.</p>
                            </div>
                            <div style={{display:"flex"}}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30" height="30">
                              <circle cx="12" cy="12" r="10" fill="#ff4e00" /> 
                              <path
                                fill="white"
                                d="M8.5 12.5l-2.5-2.5-1.42 1.41 3.92 3.92L17.92 8.5l-1.42-1.42z"
                              /> 
                            </svg>
                              <p style={{marginLeft:"5px",textAlign:"left",marginTop:"3px",color:"white"}}>We do the research for you and send to your account properties that matches your requirement.</p>
                            </div>
                          </div>
                        </MDBCardText>
                    </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                </MDBRow>
            </center>
        </div>
        <h2 style={{marginTop:"4%",color:"#ff4e00",fontSize:"35px"}}>Ready to Get Started?</h2>
        <MDBRow style={{margin:"30px"}}>
          <MDBCol md={6}>
            <p style={{color:"white",marginTop:"15px"}}>“Have you found a property you want us  looking <br /> into before you commit? </p>
            <Link to="/register"><MDBBtn style={{color:"white",backgroundColor:"#ff4e00"}}>Create Seekers Account</MDBBtn></Link>
          </MDBCol>
          <MDBCol md={6}>
            <p style={{color:"white",marginTop:"15px"}}>Need Assistance with property search? <br /> Still don't know what you want or haven't found it yet?</p>
            <Link to="/contact"><MDBBtn style={{color:"white",backgroundColor:"#ff4e00"}}>Get in touch</MDBBtn></Link>
          </MDBCol>
        </MDBRow>
        <Footer/>
    </div>
  )
}
