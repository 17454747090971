import React from "react";
import { Link } from "react-router-dom";

import { useEffect, useRef, useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
} from "mdb-react-ui-kit";
export default function Footer() {
  const [centredModal, setCentredModal] = useState(false);

  const toggleOpen = () => setCentredModal(!centredModal);

  const [centredModal1, setCentredModal1] = useState(false);

  const toggleOpen1 = () => setCentredModal1(!centredModal1);
  return (
    <>
      <div style={{ position: "relative", width: "100%" }}>
        <img
          src="../Assets/wave.png"
          alt=""
          className="waveimage"
          style={{ width: "100%" }}
        />
        <div
          style={{
            position: "absolute",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "100%",
            color: "white",
            fontSize: "18px",
          }}
        >
          <div
            className="flex-wrap"
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "space-around",
            }}
          >
            <p>&copy; 2024 Asare Viewing</p>
            <p>Powered by Genaltiece Ltd</p>
            <div>
              <a
                className="text-light"
                target="_blank"
                href="https://www.facebook.com/share/u3nZBChMaaqgE38Z/?mibextid=LQQJ4d"
              >
                <i
                  className="fa fa-facebook"
                  style={{ fontSize: "20px", marginRight: "20px" }}
                ></i>
              </a>

              <a
                className="text-light"
                target="_blank"
                href="https://www.x.com/asareviewing"
              >
                <i
                  className="fa fa-twitter"
                  style={{ fontSize: "20px", marginRight: "20px" }}
                ></i>
              </a>

              <a
                className="text-light"
                target="_blank"
                href="https://www.instagram.com/asareviewing?igsh=MWo3dmpnamR1dmF3Nw%3D%3D&utm_source=qr"
              >
                <i
                  className="fa fa-instagram"
                  style={{ fontSize: "20px", marginRight: "20px" }}
                ></i>
              </a>
              <a
                className="text-light"
                target="_blank"
                href="https://www.linkedin.com/company/asareviewing/"
              >
                <i
                  className="fa fa-linkedin"
                  style={{ fontSize: "20px", marginRight: "20px" }}
                ></i>
              </a>

              <a
                className="text-light"
                target="_blank"
                href="https://youtube.com/@asareviewing?si=z2xVchTtyU_Hghoz"
              >
                <i
                  className="fa fa-youtube"
                  style={{ fontSize: "20px", marginRight: "20px" }}
                ></i>
              </a>

              <p></p>
            </div>

            <Link to="/contact" style={{ color: "white" }}>
              Contact Us
            </Link>
          </div>


<div className="d-flex align-baseline flex-wrap mx-5 justify-content-between">
          <p
            className="text-start"
            style={{ fontSize: "12.5px" }}
          >
            Asare Viewing Ltd is a company registered in England and Wales
            (Company No.15771232) | One Business Village, Emily Street, Hull,
            HU9 1ND.
          </p>

          <p>
            <span
              style={{ cursor: "pointer", fontSize:"12.5px", textDecoration: "underline" }}
              onClick={toggleOpen}
            >
              Privacy Policy{" | "}
            </span>
            <span
              style={{ cursor: "pointer", fontSize:"12.5px", textDecoration: "underline" }}
              onClick={toggleOpen1}
            >
              Terms and Conditions
            </span>
          </p>

          </div>

        
        </div>
      </div>
      <MDBModal
        tabIndex="-1"
        open={centredModal}
        onClose={() => setCentredModal(false)}
      >
        <MDBModalDialog size="lg" bottom>
          <MDBModalContent className="privacy">
            <MDBModalHeader>
              <MDBModalTitle className="text-center w-100 ">
                <h2 style={{ color: "#ff4e00" }}>Privacy Policy</h2>
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody style={{ textAlign: "justify" }}>
              <p>
                This Privacy Policy applies between you, the User of this
                Website, and Asare Viewing Ltd, the owner and provider of this
                Website. Asare Viewing Ltd takes the privacy of your information
                very seriously. This Privacy Policy applies to our use of any
                and all Data collected by us or provided by you in relation to
                your use of the Website.
              </p>
              <p>
                This Privacy Policy should be read alongside, and in addition
                to, our Terms and Conditions, which can be found at:{" "}
                <a href="/viewer-terms" style={{ color: "#FF4E00" }}>
                  asareviewing.com/terms-and-conditions
                </a>
              </p>
              <p>Please read this Privacy Policy carefully.</p>
              <h5 className="h5">Definitions and Interpretation</h5>
              <p>In this Privacy Policy, the following definitions are used:</p>
              <ul>
                <li>
                  <strong>Data:</strong> collectively all information that you
                  submit to Asare Viewing Ltd via the Website. This definition
                  incorporates, where applicable, the definitions provided in
                  the Data Protection Laws;
                </li>
                <li>
                  <strong>Data Protection Laws:</strong> any applicable law
                  relating to the processing of personal Data, including but not
                  limited to the GDPR, and any national implementing and
                  supplementary laws, regulations and secondary legislation;
                </li>
                <li>
                  <strong>GDPR:</strong> the UK General Data Protection
                  Regulation;
                </li>
                <li>
                  <strong>Asare Viewing Ltd, We or Us:</strong> Asare Viewing
                  Ltd, a company incorporated in England and Wales with
                  registered number 15771232 whose registered office is at ONE
                  BUSINESS VILLAGE, EMILY STREET, East Riding Yorkshire, HUS
                  IND;
                </li>
                <li>
                  <strong>User or you:</strong> any third party that accesses
                  the Website and is not either (1) employed by Asare Viewing
                  Ltd and acting in the course of their employment or (ii)
                  engaged as a consultant or otherwise providing services to
                  Asare Viewing Ltd and accessing the Website in connection with
                  the provision of such services; and
                </li>
                <li>
                  <strong>Website:</strong> the website that you are currently
                  using, asareviewing.com, and any sub-domains of this site
                  unless expressly excluded by their own terms and conditions.
                </li>
              </ul>
              <h5 className="h5">Scope of this Privacy Policy</h5>
              <p>
                This Privacy Policy applies only to the actions of Asare Viewing
                Ltd and Users with respect to this Website. It does not extend
                to any websites that can be accessed from this Website
                including, but not limited to, any links we may provide to
                social media websites.
              </p>
              <p>
                For purposes of the applicable Data Protection Laws, Asare
                Viewing Ltd is the "data controller". This means that Asare
                Viewing Ltd determines the purposes for which, and the manner in
                which, your Data is processed.
              </p>
              <h5 className="h5">Data Collected</h5>
              <p>
                We may collect the following Data, which includes personal Data,
                from you:
              </p>
              <ul>
                <li>name;</li>
                <li>date of birth;</li>
                <li>gender;</li>
                <li>
                  contact Information such as email addresses and telephone
                  numbers;
                </li>
                <li>
                  demographic information such as postcode, preferences and
                  interests;
                </li>
                <li>
                  financial information such as credit / debit card numbers;
                </li>
              </ul>
              <h5 className="h5">How We Collect Data</h5>
              <p>We collect data in the following ways:</p>
              <ul>
                <li>Data given to us by you; and </li>
                <li>Data collected automatically.</li>
              </ul>
              <h5 className="h5">Data That Is Given To Us By You</h5>
              <p>
                Asare Viewing Ltd will collect your Data in a number of ways,
                for example:
              </p>
              <ul>
                <li>
                  When you contact us through the Website, by telephone, post,
                  e-mail or through any other means;
                </li>
                <li>
                  when you register with us and set up an account to receive our
                  products/services;
                </li>
                <li>
                  when you complete surveys that we use for research purposes
                  (although you are not obliged to respond to them);
                </li>
                <li>
                  when you make payments to us, through this Website or
                  otherwise;
                </li>
                <li>
                  when you elect to receive marketing communications from us;
                </li>
                <li>when you use our services;</li>
              </ul>
              <h5 className="h5">Data That Is Collected Automatically</h5>
              <p>
                To the extent that you access the Website, we will collect your
                Data automatically, for example:
              </p>
              <ul>
                <li>
                  we automatically collect some information about your visit to
                  the Website. This information helps us to make improvements to
                  Website content and navigation, and includes your IP address,
                  the date, times and frequency with which you access the
                  Website and the way you use and interact with its content.
                </li>
              </ul>
              <h5 className="h5">Our Use of Data</h5>
              <p>
                Any or all of the above Data may be required by us from time to
                time in order to provide you with the best possible service and
                experience when using our Website. Specifically, Data may be
                used by us for the following reasons:
              </p>
              <ul>
                <li>Internal record keeping;</li>
                <li>Improvement of our products / services;</li>
                <li>
                  Transmission by email of marketing materials that may be of
                  interest to you;
                </li>
                <li>
                  Contact for market research purposes which may be done using
                  email, telephone, fax or mail. Such information may be used to
                  customise or update the website;
                </li>
              </ul>
              <p>
                We may use your Data for the above purposes if we deem it
                necessary to do so for our legitimate interests. If you are not
                satisfied with this, you have the right to object in certain
                circumstances (see the section headed "Your rights" below).
              </p>
              <h5 className="h5">Who We Share Data With</h5>
              <p>
                We may share your Data with the following groups of people for
                the following reasons:
              </p>
              <ul>
                <li>
                  any of our group companies or affiliates – to enable us to
                  provide proper administration of our business and website;
                </li>
                <li>
                  our employees, agents and/or professional advisors - to obtain
                  advice as well as to help in providing you with a tailored
                  service;
                </li>
                <li>
                  third party payment providers who process payments made over
                  the Website - to enable third party payment providers to
                  process user payments and refunds;
                </li>
                <li>
                  relevant authorities - to facilitate the detection and
                  investigation of crime or the collection of taxes or duties;
                </li>
              </ul>
              <h5 className="h5">Keeping Data Secure</h5>
              <p>
                We will use technical and organisational measures to safeguard
                your data, for example:
              </p>
              <ul>
                <li>
                  access to your account is controlled by a password and a user
                  name that is unique to you;
                </li>
                <li>we store your Data on secure servers;</li>
                <li>
                  payment details are encrypted using SSL technology (typically
                  you will see a lock icon or green address bar (or both) in
                  your browser when we use this technology).
                </li>
              </ul>
              <p>
                Technical and organisational measures include measures to deal
                with any suspected data breach. If you suspect any misuse or
                loss or unauthorized access to your Data, please let us know
                immediately by contacting us via this e-mail address:{" "}
                <span>
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&to=contactus@asareviewing.com"
                    target="_blank"
                    className="anchor"
                  >
                    contactus@asareviewing.com
                  </a>
                </span>
              </p>
              <p>
                If you want detailed information from Get Safe Online on how to
                protect your information and your computers and devices against
                fraud, identity theft, viruses and many other online problems,
                please visit www.getsafeonline.org. Get Safe Online is supported
                by HM Government and leading businesses.
              </p>
              <h5 className="h5">Data Retention</h5>
              <p>
                Unless a longer retention period is required or permitted by
                law, we will only hold your Data on our systems for the period
                necessary to fulfil the purposes outlined in this Privacy Policy
                or until you request that the Data be deleted.
              </p>
              <p>
                Even if we delete your Data, it may persist on backup or
                archival media for legal, tax or regulatory purposes.
              </p>
              <h5 className="h5">Your Rights</h5>
              <p>You have the following rights in relation to your data:</p>
              <ul>
                <li>
                  <strong>Right to access:</strong> the right to request (i)
                  copies of the information we hold about you at any time, or
                  (ii) that we modify, update or delete such information. If we
                  provide you with access to the information we hold about you,
                  we will not charge you for this, unless your request is
                  "manifestly unfounded or excessive." Where we are legally
                  permitted to do so, we may refuse your request. If we refuse
                  your request, we will tell you the reasons why.
                </li>
                <li>
                  <strong>Right to correct:</strong> the right to have your Data
                  rectified if it is inaccurate or incomplete.
                </li>
                <li>
                  <strong>Right to erase:</strong> the right to request that we
                  delete or remove your Data from our systems.
                </li>
                <li>
                  <strong>Right to restrict our use of your Data:</strong> the
                  right to "block" us from using your Data or limit the way in
                  which we can use it.
                </li>
                <li>
                  <strong>Right to data portability:</strong> the right to
                  request that we move, copy or transfer your Data.
                </li>
                <li>
                  <strong>Right to object:</strong> the right to object to our
                  use of your Data including where we use it for our legitimate
                  interests.
                </li>
              </ul>
              <p>
                To make enquiries, exercise any of your rights set out above, or
                withdraw your consent to the processing of your Data (where
                consent is our legal basis for processing your Data), please
                contact us via this e-mail address:{" "}
                <span>
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&to=contactus@asareviewing.com"
                    target="_blank"
                    className="anchor"
                  >
                    contactus@asareviewing.com
                  </a>
                </span>
              </p>
              <p>
                If you are not satisfied with the way a complaint you make in
                relation to your Data is handled by us, you may be able to refer
                your complaint to the relevant data protection authority. For
                the UK, this is the Information Commissioner's Office (ICO). The
                ICO's contact details can be found on their website at
                https://ico.org.uk/.
              </p>
              <p>
                It is important that the Data we hold about you is accurate and
                current. Please keep us informed if your Data changes during the
                period for which we hold it.
              </p>
              <h5 className="h5">
                Transfers Outside The United Kingdom And European Economic Area
              </h5>
              <p>
                Data which we collect from you may be stored and processed in
                and transferred to countries outside of the UK and European
                Economic Area (EEA). For example, this could occur if our
                servers are located in a country outside the UK or EEA or one of
                our service providers is situated in a country outside the UK or
                EEA.
              </p>
              <p>
                We will only transfer Data outside the UK or EEA where it is
                compliant with data protection legislation and the means of
                transfer provides adequate safeguards in relation to your data,
                e.g. by way of data transfer agreement, incorporating the
                current standard contractual clauses adopted by the European
                Commission.
              </p>
              <p>
                To ensure that your Data receives an adequate level of
                protection, we have put in place appropriate safeguards and
                procedures with the third parties we share your Data with. This
                ensures your Data is treated by those third parties in a way
                that is consistent with the Data Protection Laws.
              </p>
              <h5 className="h5">Links To Other Websites</h5>
              <p>
                This Website may, from time to time, provide links to other
                websites. We have no control over such websites and are not
                responsible for the content of these websites. This Privacy
                Policy does not extend to your use of such websites. You are
                advised to read the Privacy Policy or statement of other
                websites prior to using them.
              </p>
              <h5 className="h5">Changes Of Business, Ownership And Control</h5>
              <p>
                Asare Viewing Ltd may, from time to time, expand or reduce our
                business and this may involve the sale and/or the transfer of
                all or part of Asare Viewing Ltd. Data provided by Users will,
                where it is relevant to any part of our business so transferred,
                be transferred along with that part and the new owner or newly
                controlling party will, under the terms of this Privacy Policy,
                be permitted to use for the purposes for which it was originally
                supplied to us.
              </p>
              <p>
                We may also disclose Data to a prospective purchaser of our
                business or any part of it.
              </p>
              <p>
                In the above instances, we will take steps with the aim of
                ensuring your privacy is protected.
              </p>
              <h5 className="h5">General</h5>
              <p>
                You may not transfer any of your rights under this Privacy
                Policy to any other person. We may transfer our rights under
                this Privacy Policy where we reasonably believe your rights will
                not be affected.
              </p>
              <p>
                If any court or competent authority finds that any provision of
                this Privacy Policy (or part of any provision) is invalid,
                illegal or unenforceable, that provision or part-provision will,
                to the extent required, be deemed to be deleted, and the
                validity and enforceability of the other provisions of this
                Privacy Policy will not be affected.
              </p>
              <p>
                Unless otherwise agreed, no delay, act or omission by a party in
                exercising any right or remedy will be deemed a waiver of that,
                or any other, right or remedy.
              </p>
              <p>
                This Agreement will be governed by and interpreted according to
                the law of England and Wales. All disputes arising under the
                Agreement will be subject to the exclusive jurisdiction of the
                English and Welsh courts.
              </p>
              <h5 className="h5">Changes To This Privacy Policy</h5>
              <p>
                Asare Viewing Ltd reserves the right to change this Privacy
                Policy as we may deem necessary from time to time or as may be
                required by law. Any changes will be immediately posted on the
                Website and you are deemed to have accepted the terms of the
                Privacy Policy on your first use of the Website following the
                alterations.
              </p>
              <p>
                You may contact Asare Viewing Ltd by email at{" "}
                <span>
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&to=contactus@asareviewing.com"
                    target="_blank"
                    className="anchor"
                  >
                    contactus@asareviewing.com
                  </a>
                </span>
              </p>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                className="buttonclose"
                onClick={toggleOpen}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal
        tabIndex="-1"
        open={centredModal1}
        onClose={() => setCentredModal1(false)}
      >
        <MDBModalDialog size="lg" centered>
          <MDBModalContent className="privacy">
            <MDBModalHeader>
              <MDBModalTitle className="text-center w-100">
                <h3 style={{ color: "#ff4e00" }}>
                  TERMS AND CONDITIONS OF USE
                </h3>
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen1}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div style={{ textAlign: "left", color: "white" }}>
                <p>
                  These terms and conditions ("Terms") apply between you, the
                  User of this Website (including any sub-domains, unless
                  expressly excluded by their own terms and conditions), and
                  Asare Viewing, the owner and operator of this Website. Please
                  read these Terms carefully as they affect your legal rights.
                  By accessing and using this Website, you agree to comply with
                  and be bound by these Terms. If you do not agree with these
                  Terms, you should stop using the Website immediately.
                </p>

                <h3 style={{ color: "#ff4e00" }}>
                  1. Intellectual Property and Acceptable Use
                </h3>
                <p>
                  1.1 All Content included on the Website, unless uploaded by
                  Users, is the property of Asare Viewing, our affiliates, or
                  other relevant third parties. "Content" means any text,
                  graphics, images, audio, video, software, data compilations,
                  page layout, underlying code and software, and any other form
                  of information capable of being stored in a computer that
                  appears on or forms part of this Website, including any such
                  Content uploaded by Users. By continuing to use the Website,
                  you acknowledge that such Content is protected by copyright,
                  trademarks, database rights, and other intellectual property
                  rights.
                </p>
                <p>
                  1.2 You may retrieve, display, and view the Content on a
                  computer screen or mobile device, store the Content in
                  electronic form on a disk (but not on any server or other
                  storage device connected to a network), and print one copy of
                  the Content for your own personal, non-commercial use,
                  provided you keep intact all and any copyright and proprietary
                  notices.
                </p>
                <p>
                  1.3 You must not otherwise reproduce, modify, copy,
                  distribute, or use for commercial purposes any Content without
                  the written permission of Asare Viewing.
                </p>
                <p>
                  1.4 You acknowledge that you are responsible for any Content
                  you may submit via the Website, including the legality,
                  reliability, appropriateness, originality, and copyright of
                  any such Content. You may not upload to, distribute, or
                  otherwise publish through the Website any Content that (i) is
                  confidential, proprietary, false, fraudulent, libelous,
                  defamatory, obscene, threatening, invasive of privacy or
                  publicity rights, infringing on intellectual property rights,
                  abusive, illegal, or otherwise objectionable; (ii) may
                  constitute or encourage a criminal offense, violate the rights
                  of any party, or otherwise give rise to liability or violate
                  any law; or (iii) may contain software viruses, political
                  campaigning, chain letters, mass mailings, or any form of
                  "spam." You may not use a false email address or other
                  identifying information, impersonate any person or entity, or
                  otherwise mislead as to the origin of any Content.
                </p>
                <p>
                  1.5 You represent and warrant that you own or otherwise
                  control all the rights to the Content you post; that the
                  Content is accurate; that use of the Content you supply does
                  not violate any provision of these Terms and will not cause
                  injury to any person; and that you will indemnify Asare
                  Viewing for all claims resulting from Content you supply.
                </p>

                <h3 style={{ color: "#ff4e00" }}>2. Prohibited Use</h3>
                <p>
                  2.1 You may not use the Website for any purpose that is
                  unlawful or prohibited by these Terms.
                </p>
                <p>
                  2.2 You may not use the Website in any manner that could
                  damage, disable, overburden, or impair the Website or
                  interfere with any other party's use and enjoyment of the
                  Website.
                </p>
                <p>
                  2.3 You may not make, transmit, or store electronic copies of
                  Content protected by copyright without the permission of the
                  owner.
                </p>

                <h3 style={{ color: "#ff4e00" }}>3. Registration</h3>
                <p>
                  3.1 You must ensure that the details provided by you on
                  registration or at any time are correct and complete.
                </p>
                <p>
                  3.2 You must inform us immediately of any changes to the
                  information that you provide when registering by updating your
                  personal details to ensure we can communicate with you
                  effectively.
                </p>
                <p>
                  3.3 We may suspend or cancel your registration with immediate
                  effect for any reasonable purposes or if you breach these
                  Terms.
                </p>
                <p>
                  3.4 You may cancel your registration at any time by informing
                  us in writing to the address at the end of these Terms. If you
                  do so, you must immediately stop using the Website.
                  Cancellation or suspension of your registration does not
                  affect any statutory rights.
                </p>

                <h3 style={{ color: "#ff4e00" }}>4. Passwords and Security</h3>
                <p>
                  4.1 When you register on this Website, you will be asked to
                  create a password, which you should keep confidential and not
                  disclose or share with anyone.
                </p>
                <p>
                  4.2 If we have reason to believe that there is or is likely to
                  be any misuse of the Website or breach of security, we may
                  require you to change your password or suspend your account.
                </p>

                <h3 style={{ color: "#ff4e00" }}>5. Privacy Policy</h3>
                <p>
                  5.1 Use of the Website is also governed by our Privacy Policy,
                  which is incorporated into these Terms by this reference. To
                  view the Privacy Policy, please click on the following link:{" "}
                  <a
                    href="asareviewing.com/privacy-policy"
                    style={{ color: "#ff4e00" }}
                  >
                    Privacy Policy
                  </a>
                  .
                </p>

                <h3 style={{ color: "#ff4e00" }}>
                  6. Availability of the Website and Disclaimers
                </h3>
                <p>
                  6.1 Any online facilities, tools, services, or information
                  that Asare Viewing makes available through the Website (the
                  "Service") is provided "as is" and on an "as available" basis.
                  We give no warranty that the Service will be free of defects
                  and/or faults. To the maximum extent permitted by law, we
                  provide no warranties (express or implied) of fitness for a
                  particular purpose, accuracy of information, compatibility,
                  and satisfactory quality. Asare Viewing is under no obligation
                  to update information on the Website.
                </p>
                <p>
                  6.2 Whilst Asare Viewing uses reasonable endeavors to ensure
                  that the Website is secure and free of errors, viruses, and
                  other malware, we give no warranty or guarantee in that regard
                  and all Users take responsibility for their own security, that
                  of their personal details, and their computers.
                </p>
                <p>
                  6.3 Asare Viewing accepts no liability for any disruption or
                  non-availability of the Website.
                </p>
                <p>
                  6.4 Asare Viewing reserves the right to alter, suspend, or
                  discontinue any part (or the whole) of the Website, including
                  but not limited to any products and/or services available.
                  These Terms shall continue to apply to any modified version of
                  the Website unless it is expressly stated otherwise.
                </p>

                <h3 style={{ color: "#ff4e00" }}>
                  7. Limitations of Liabilities
                </h3>
                <p>
                  7.1 Nothing in these Terms will limit or exclude our or your
                  liability for death or personal injury resulting from
                  negligence, fraud, fraudulent misrepresentation, or any other
                  liability that cannot be excluded or limited by applicable
                  law.
                </p>
                <p>
                  7.2 We will not be liable to you in respect of any losses
                  arising out of events beyond our reasonable control.
                </p>
                <p>
                  7.3 To the maximum extent permitted by law, Asare Viewing
                  accepts no liability for any business losses, such as loss of
                  profits, income, revenue, anticipated savings, business,
                  contracts, goodwill, or commercial opportunities; loss or
                  corruption of any data, database, or software; or any special,
                  indirect, or consequential loss or damage.
                </p>

                <h3 style={{ color: "#ff4e00" }}>8. General</h3>
                <p>
                  8.1 You may not transfer any of your rights under these Terms
                  to any other person. We may transfer our rights under these
                  Terms where we reasonably believe your rights will not be
                  affected.
                </p>
                <p>
                  8.2 We may revise these Terms at any time by updating this
                  page. You should check this page regularly to review the
                  current Terms, as they are binding on you. Certain provisions
                  of these Terms may be superseded by expressly designated legal
                  notices or terms located on particular pages at this Website.
                </p>
                <p>
                  8.3 These Terms, together with our Privacy Policy, constitute
                  the entire agreement between you and Asare Viewing in relation
                  to your use of this Website and supersede all prior agreements
                  and understandings.
                </p>
                <p>
                  8.4 The Contracts (Rights of Third Parties) Act 1999 shall not
                  apply to these Terms, and no third party will have any right
                  to enforce or rely on any provision of these Terms.
                </p>
                <p>
                  8.5 If any court or competent authority finds that any
                  provision of these Terms (or part of any provision) is
                  invalid, illegal, or unenforceable, that provision or
                  part-provision will, to the extent required, be deemed to be
                  deleted, and the validity and enforceability of the other
                  provisions of these Terms will not be affected.
                </p>
                <p>
                  8.6 Unless otherwise agreed, no delay, act, or omission by a
                  party in exercising any right or remedy will be deemed a
                  waiver of that, or any other, right or remedy.
                </p>
                <p>
                  8.7 This Agreement shall be governed by and interpreted
                  according to the law of England and Wales, and all disputes
                  arising under the Agreement (including non-contractual
                  disputes or claims) shall be subject to the exclusive
                  jurisdiction of the English and Welsh courts.
                </p>

                <h3 style={{ color: "#ff4e00" }}>9. Contact Details</h3>
                <p>
                  Asare Viewing is a company incorporated in England and Wales
                  with registered number Asare Viewing Ltd whose registered
                  address is One Business Village, Emily Street, East Riding of
                  Yorkshire, HUS IND and it operates the Website
                  asareviewing.com. You can contact Asare Viewing by email at{" "}
                  <a
                    href="mailto:contactus@asareviewing.com"
                    style={{ color: "#ff4e00" }}
                  >
                    contactus@asareviewing.com
                  </a>
                  .
                </p>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                className="buttonclose"
                color="secondary"
                onClick={toggleOpen1}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
