import React, { useEffect, useState } from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { MDBBtn, MDBInput, MDBSpinner, MDBTextArea } from "mdb-react-ui-kit";
import WrappedMapContainer from "./map";
import Navbar from "../Components/navbar";
import { toast, ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import Lognavbar from "../Components/lognavbar";
import axios from "axios";
import Footer from "./footer";

export default function Contact() {
  useEffect(() => {
    document.body.style.fontFamily = "bahnschrift";
    document.body.style.backgroundColor = "#000000";
    document.body.style.overflowX = "hidden";
  }, []);

  const initialForm = {
    name: "",
    phone: "",
    email: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialForm);
  const [submit, setSubmit] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "profilePicture") {
      const file = files[0];

      setFormData((prevFormData) => ({
        ...prevFormData,
        profilePicture: file,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  async function handleContact(e) {
    e.preventDefault();

    try {
      setSubmit(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/asare/contact`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = response.data;
      if (responseData.message == "success") {
        successNotify();
      }
      setFormData(initialForm);
      setSubmit(false);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const successNotify = () => {
    toast.success("Message Sent", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  return (
    <div>
      {Cookies.get("Id") ? <Navbar /> : <Lognavbar />}
      <ToastContainer />
      <h1 className="mainheading" style={{ color: "#ff4e00", marginTop: "4%" }}>
        Contact Us
      </h1>
      <p style={{ color: "white", marginTop: "-20px" }}>
        contactus@asareviewing.com | +447405253088
      </p>
      <MDBRow className="removemargin">
        <MDBCol md={7}>
          <center>
            <div className=" formss">
              <div className="mb-4">
                <label
                  htmlFor="form1"
                  style={{
                    textAlign: "left",
                    display: "block",
                    color: "white",
                  }}
                >
                  Name
                </label>
                <MDBInput
                  size="lg"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="form1"
                  style={{
                    textAlign: "left",
                    display: "block",
                    color: "white",
                  }}
                >
                  Email
                </label>
                <MDBInput
                  size="lg"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="form1"
                  style={{
                    textAlign: "left",
                    display: "block",
                    color: "white",
                  }}
                >
                  Phone No
                </label>
                <MDBInput
                  size="lg"
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="form1"
                  style={{
                    textAlign: "left",
                    display: "block",
                    color: "white",
                  }}
                >
                  Message
                </label>
                <MDBTextArea
                  wrapperClass="mb-4"
                  rows={5}
                  required
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                />
              </div>

              <MDBBtn
                className="mb-4 w-100 gradient-custom-4"
                size="lg"
                style={{
                  borderRadius: "0",
                  color: "black",
                  backgroundColor: "white",
                }}
                onClick={handleContact}
              >
                {submit ? <MDBSpinner></MDBSpinner> : <span>Submit</span>}
              </MDBBtn>
            </div>
          </center>
        </MDBCol>
        <MDBCol md={5}>
          <div style={{ marginTop: "5%" }}>
            <img src="map.png" style={{ height: "400px" }}/>
              {/* <WrappedMapContainer /> */}

              <h4 className=" my-3 text-white fw-bolder">Serving Customers Nationwide Across the UK.</h4>
            <p style={{ marginTop: "7px", color: "white",textAlign:"justify",marginRight:"20px",marginLeft:"20px" }}>
            At Asare Viewing Ltd, we serve customers nationwide across the UK with teams in major cities and regions in England, Wales. From London to Liverpool, Manchester to Plymouth, our local expertise ensures comprehensive real estate services for both international and domestic clients. Whether you're planning a trip to the UK or searching for properties, our experts are ready to assist you in finding and securing the perfect property. Contact us today to achieve your real estate goals anywhere in the UK.







            </p>
          </div>
        </MDBCol>
      </MDBRow>

      <Footer />
    </div>
  );
}
